/*
 * @Author: jpang6 jpang6@slb.com
 * @Date: 2023-08-21 11:09:13
 * @LastEditTime: 2023-08-21 11:09:13
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeSwitcherComponent } from './core/theme-switcher/theme-switcher.component';
import { ComputationDashboardComponent } from './modules/computation/computation-dashboard/computation-dashboard.component';
import { ProjectDashbordComponent } from './modules/landingpage/project-dashbord/project-dashbord.component';
import { WelcomeComponent } from './modules/landingpage/welcome/welcome.component';


const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashbord',
    component: ProjectDashbordComponent,
    data: {
      title: '延长石油-混合井网智能部署平台',
    },
  },
  {
    path: 'computation',
    component: ComputationDashboardComponent,
    data: {
      title: '延长石油-混合井网智能部署平台',
    },
  },
  {
    path: 'themes',
    component: ThemeSwitcherComponent,
    data: {
      title: 'Theme Switching',
      showHeader: false,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
