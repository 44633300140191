import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { FileHelper } from 'src/app/core/helpers/file-helper';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { GiipModel } from 'src/app/shared/models/surfaces/giip.model';
import { SurfaceDialogDataModel } from 'src/app/shared/models/surfaces/surface-dialog-data.model';
import { SurfaceUploadModel } from 'src/app/shared/models/surfaces/surface-upload.model';
import { UploadDataModel } from 'src/app/shared/models/upload-data.model';

@Component({
  selector: 'app-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss']
})


export class UploadPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploadPopupComponent>,
    private readonly messageService: MessageService,
    private readonly surfaceService: SurfaceService,
    private readonly fileHelper: FileHelper,
    @Inject(MAT_DIALOG_DATA) public data: SurfaceDialogDataModel
  ) { }

  get isValid(): boolean {
    let  errorCount = 0;
    if (this.data.surfaceParameter!= null)
    {
      this.data.surfaceParameter.forEach(element => {
        if (element.giipModel.geologicalParameter > 1 || element.giipModel.geologicalParameter < 0 || element == null){
         errorCount += 1
        }
     });
    }else {
      errorCount += 1
    }
   
    return errorCount==0 ? true: false;
  }

  displayedColumns = ["surfaceName", "geologicalParameter"]

  ngOnInit(): void {
  }

  onClose(event) {
    this.surfaceService.addUploadingDataSubjectEvent(false);
  }

  async onSurfaceUpload() {
    this.surfaceService.addUploadingDataSubjectEvent(true);
    var promises = [];
    for (let file of this.data.surfaceParameter) {
      const formData = new FormData();
      formData.append("file", file.surfaceFile);
      formData.append("giip.geologicalParameter", file.giipModel.geologicalParameter.toString());
      promises.push(this.surfaceService.upload(formData, this.data.projectId).toPromise());
    }
    Promise.all(promises).then(() => {
      this.messageService.add({ severity: SlbSeverity.Success, detail: '上传完成!', summary: '操作完成', asHtml: false, target: 'toast' });
    }).catch(err => {
      this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
    }).finally(() => {
      this.surfaceService.addRefreshDataSubjectEvent();
      this.surfaceService.addUploadingDataSubjectEvent(false);
    });
  }

}
