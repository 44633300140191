<div fxLayout="row" fxFill>
    <section fxFlex class="mat-app-background tab-group">
        <mat-tab-group [slbTabTitle]="'工区-'+ projectName" [(selectedIndex)]="selectedTabIndex" mat-align-tabs="end">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>地面工程</span>
                </ng-template>
                <app-map-input *ngIf="projectId && planId" [isEditable]="true" [projectId]="projectId" [planId]="planId"></app-map-input>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>地下信息</span>
                </ng-template>
                <app-layer-input *ngIf="projectId && planId" [projectId]="projectId" [planId]="planId"></app-layer-input>
            </mat-tab>
        </mat-tab-group>
    </section>
</div>

