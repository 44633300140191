<div fxFlex fxLayout="column">
    <h1 mat-dialog-title>编辑方案</h1>
    <div fxFlex fxLayout="column" class="content" mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>名称</mat-label>
        <input matInput [(ngModel)]="model.name" >
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-raised-button class= "confirm-button"[disabled]="!isValid" (click)="onYesClick()" cdkFocusInitial>{{data.projectId ? '确认' : '创建'}}</button>
      <button mat-button (click)="onNoClick()">关闭</button> 
    </div>
  </div>