import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComputationService } from 'src/app/core/services/computation.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { WellService } from 'src/app/core/services/well.service';
import { WellType } from 'src/app/shared/enums/wells/well-type.enum';
import { ConfirmDialogModel } from 'src/app/shared/models/confirm-dialog.model';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { GeologicalParaModel, SurfaceDialogDataModel } from 'src/app/shared/models/surfaces/surface-dialog-data.model';
import { SurfaceModel, SurfaceViewModel } from 'src/app/shared/models/surfaces/surface.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { UploadPopupComponent } from '../computation-input/popup/upload-popup/upload-popup.component';

@Component({
  selector: 'app-layer-manager',
  templateUrl: './layer-manager.component.html',
  styleUrls: ['./layer-manager.component.scss']
})
export class LayerManagerComponent implements OnInit {

  @Input()
  projectId: string;

  @Input()
  planId: string;

  @Output()
  wellSelect = new EventEmitter<WellModel[]>();

  @Output()
  surfaceSelect = new EventEmitter<SurfaceViewModel[]>();

  wellColumns = new Map<string, string>([
    ["name", "井名"],
  ]);
  wellSource: WellModel[] = [];
  selectedWells: WellModel[] = [];

  surfaceColumns = new Map<string, string>([
    ["name", "层面名称"],
    ["geologicalParameter",  "地层体积系数"]
  ]);
  surfaceSource: SurfaceModel[] = [];
  selectedSurfaces: SurfaceViewModel[] = [];
  surfaceViewData: SurfaceViewModel[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ifReload: boolean = false;

  wellSubscription: Subscription;
  surfaceSubscription: Subscription;

  constructor(
    private readonly computationService: ComputationService,
    private readonly surfaceService: SurfaceService,
    private readonly wellService: WellService,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService) { }

  ngOnInit(): void {
    this.getWellData();
    this.wellSubscription = this.wellService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getWellData();
    });

    this.getSurfaceData();
    this.surfaceSubscription = this.surfaceService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getSurfaceData();
    });

    this.surfaceService.getUploadingDataSubjectEvent().subscribe(isLoading => {
      if (isLoading){
        this.isLoading$.next(true);
      }else {
        this.isLoading$.next(false);
        this.ifReload = true;
      }
    })
  }

  ngOnDestroy() {
    this.wellSubscription.unsubscribe()
    this.surfaceSubscription.unsubscribe()
  }

  getWellData() {
    this.wellService.getList(this.projectId, null, true).subscribe(res => {
      res.data.forEach(well => well.wellTypeName = this.getWellTypeName(well.wellType))
      this.wellSource = res.data;
    })
  }

  getSurfaceData() {
    this.surfaceViewData = [];
    this.surfaceService.getList(this.projectId).subscribe(res => {
      this.surfaceSource = res.data;
      res.data.forEach(element => {
        this.surfaceViewData.push( {
          name: element.name,
          geologicalParameter: element.giipModel.geologicalParameter,
          id: element.id,
          location: element.location
        } as SurfaceViewModel)
      })
    })
  }

  onWellSelect(data: WellModel[]) {
    this.selectedWells = data;
    this.wellSelect.emit(data);
  }

  onWellDelete() {
    if (this.selectedWells.length > 0) {
      const dialogData = new ConfirmDialogModel("删除方案", `是否确认删除所选项？`);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          var promises = [];
          for (let well of this.selectedWells) {
            promises.push(this.wellService.delete(well.id).toPromise());
          }
          Promise.all(promises).then(() => {
            this.messageService.add({ severity: SlbSeverity.Success, detail: '删除完成!', summary: '操作完成', asHtml: false, target: 'toast' });
          }).catch(err => {
            this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
          }).finally(() => {
            this.wellService.addRefreshDataSubjectEvent();
          });
        }
      });
    }
  }

  onSurfaceSelect(data: SurfaceViewModel[]) {
    this.selectedSurfaces = data;
    let selectedSurfaceIds = []
    data.forEach(item=>{
      selectedSurfaceIds.push(item.id)
    })
    this.surfaceService.selectedSurfaceIds = selectedSurfaceIds
    this.surfaceSelect.emit(data);
    
  }

  onSurfaceNameUpdate(surfaceParameter: GeologicalParaModel[]): void {
    this.ifReload = false;
    const dialogRef = this.dialog.open(UploadPopupComponent, {
        width: '540px',
        data: { 
            projectId: this.projectId ,
            surfaceParameter: surfaceParameter,
        } as SurfaceDialogDataModel,
    });
    dialogRef.afterClosed().subscribe((result: DialogStateModel<string>) => {
    });
  }
  
  onSurfaceDelete() {
    if (this.selectedSurfaces.length > 0) {
      const dialogData = new ConfirmDialogModel("删除方案", `是否确认删除所选项？`);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.isLoading$.next(true);
          var promises = [];
          for (let surface of this.selectedSurfaces) {
            promises.push(this.surfaceService.delete(surface.id).toPromise());
          }
          Promise.all(promises).then(() => {
            this.messageService.add({ severity: SlbSeverity.Success, detail: '删除完成!', summary: '操作完成', asHtml: false, target: 'toast' });
          }).catch(err => {
            this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
          }).finally(() => {
            this.surfaceService.addRefreshDataSubjectEvent();
            this.isLoading$.next(false);
          });
        }
      });
    }
  }

  private getWellTypeName(type: WellType): string {
    let result = '';
    switch (type){
      case WellType.Deviated: result = '定向井'; break;
      case WellType.Horizontal: result = '水平井'; break;
      case WellType.Vertical: result = '直井'; break;
    }
    return result;
  }
}