<div fxFlex fxLayout="column" fxLayoutGap="10px" class="file-wrapper">
    <ng-content></ng-content>
    <div fxFlex fxLayout="row">
        <div fxFlex="72">
            <mat-chip-list aria-label="Fish selection">
                <mat-chip *ngFor="let selectedFile of selectedFiles">
                    {{selectedFile.name}}
                </mat-chip>
            </mat-chip-list>
        </div>
        <div class="upload-text" >
            上传
        </div>
        <div fxFlex fxLayout="row">
            <input type="file" multiple class="file-input" (click)="fileUpload.value = null" (change)="onFilesSelected($event.target.files)" #fileUpload>
            <button slb-icon-button matTooltip="上传图件"  (click)="fileUpload.click()">
                <mat-icon svgIcon="layers"></mat-icon>
            </button>
        </div>
    </div>
</div>
