<div class="radio-section">
    <div class="label-text"> 算法类型 </div>
    <slb-radio-button-group [(ngModel)]="model.selectedAlgoType" class="label-text" [isVertical]="false"
        [values]="algoOptions" (valueChanged)="onAlgoOptionChanged($event) ">
    </slb-radio-button-group>
    <hr>
</div>
<ng-container *ngIf="model.selectedAlgoType === SelectedAlgoType.InteligentWellPlanning">
    <div class="radio-section">
        <div class="label-text"> 布井优化目标 </div>
        <slb-radio-button-group  class="label-text" [isVertical]="false"
            [values]="strategyOptions">
        </slb-radio-button-group>
    </div>
</ng-container>
<ng-container *ngIf="model.selectedAlgoType === SelectedAlgoType.EqualDistance">
    <div class="radio-section">
        <slb-radio-button-group [(ngModel)]="model.equalDistance.selectedDistanceType" class="label-text" [isVertical]="false"
            [values]="fixWellSpaceOptions">
        </slb-radio-button-group>
    </div>
    <mat-form-field
        *ngIf="model.equalDistance.selectedDistanceType === SelectedDistanceType.FixedWellSpace" class="input-field" slbFormField>
        <mat-label class="label-text">均匀井距 (米)</mat-label>
        <input #input type="number" [(ngModel)]="model.equalDistance.fixedWellSpace" placeholder="默认 500米 均匀井距 " matInput required />
    </mat-form-field>
    <mat-form-field
        *ngIf="model.equalDistance.selectedDistanceType === SelectedDistanceType.FixedWellDensity" class="input-field" slbFormField>
        <mat-label class="label-text">固定井网密度 (公里)</mat-label>
        <input #input type="number" [(ngModel)]="model.equalDistance.fixedWellDensity" placeholder="默认 2.5公里" matInput required />
    </mat-form-field>
</ng-container>