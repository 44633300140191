import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input,  OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { TableControlDialogModel } from 'src/app/shared/models/table-control-dialog.model';
import { TableControlDialogComponent } from './table-control-dialog/table-control-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss']
})
export class TableControlComponent<T> implements OnInit {

  @Input()
  dataColumns = new Map<string, string>();

  _dataSource: T[] = [];
  @Input() set dataSource(value: T[]) {
    if (this._dataSource.length !== value.length) {
      this.selection.clear();
      this.selected.emit(this.selection.selected);
    }
    if (this.selectAll && value.length > 0) {
      this.selection.clear();
      this.selectAll = true;
      this.selection.select(...value);
      this.selected.emit(this.selection.selected);
    }
    this.tableSource.data = value
    this._dataSource = value;
  }
  get dataSource() {
    return this._dataSource;
  }

  @Input()
  set dataSelection(value: SelectionModel<T>) {
    this.selection = value
  }
  
  @Input()
  selectAll = false;

  @Input()
  showTotal = false;

  @Input()
  showPopUp = false;

  @Input()
  showFilter = false;

  @Output()
  selected = new EventEmitter<T[]>();

  get columns() {
    return [...this.dataColumns.keys()];
  }

  get showColumns() {
    return ['select', ...this.columns]
  }

  tableSource = new MatTableDataSource<T>()
  selection = new SelectionModel<T>(true, [])

  constructor(
    private readonly dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.tableSource.filterPredicate = (data: T, filter: string) => {
      let isMatch = false
      for (let index = 0; index < this.dataColumns.size; index++) {
        if (data[Array.from(this.dataColumns.keys())[index]].toString().trim().toLowerCase().includes(filter.trim().toLowerCase())) {
          isMatch = true
          break;
        }
      }
      return isMatch
    };
  }

  onFilter(event: Event) {
    this.tableSource.filter = (event.target as HTMLInputElement).value;
  }

  getTotal(column: string) {
    return !isNaN(this.dataSource[0][column]) ? this.dataSource.map(a => a[column]).reduce((acc, value) => acc + value, 0) : ''
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.length;
  }

  isAllFilteredSelected() {
    let coincidenceCount = 0;
    this.tableSource.filteredData.forEach(f => {
      this.selection.selected.forEach(s => {
        if (_.isEqual(f,s)){
          coincidenceCount+=1;
          return;
        }
      })
    });
    return coincidenceCount === this.tableSource.filteredData.length;
  }

  masterToggle() {
    if (this.isAllFilteredSelected()){
      this.selection.deselect(...this.selection.selected.filter(a => this.tableSource.filteredData.includes(a)))   
      return;
    } else if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.selection.selected.concat(this.tableSource.filteredData));
  }

  onSelectAll(value: boolean) {
    if (value) {
      this.masterToggle()
    }
    this.selected.emit(this.selection.selected);
  }

  onSelect(value: boolean, row) {
    if (value) {
      this.selection.toggle(row);
    }
    this.selected.emit(this.selection.selected);
  }

  onExpand() {
    const dialogRef = this.dialog.open(TableControlDialogComponent, {
      width: '850px',
      data: {
        columns: this.dataColumns,
        source: this.dataSource,
        selection: this.selection,
        showTotal: this.showTotal,
        showFilter: this.showFilter
      } as TableControlDialogModel<T>,
    });

    dialogRef.afterClosed().subscribe((dialogResult: DialogStateModel<SelectionModel<T>>) => {
      this.selected.emit(this.selection.selected);
    });
  }
}