<mat-accordion class="example-headers-align" multi="true">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class = "header-text">
                地下层面图信息
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-spinner *ngIf="isLoading$ | async" class="progress" diameter="30"></mat-spinner>
        <app-table-control class = "surface-table" [dataColumns]="surfaceColumns" [dataSource]="surfaceViewData"
            (selected)="onSurfaceSelect($event)">
            <div class="control">
                <div class = "upload-icon" >
                    <app-file-upload [ifReload]="ifReload" (onSelect)="onSurfaceNameUpdate($event)" class="multi-file-input">
                    </app-file-upload>
                </div>
                <div>
                    <button class="blue-text" 
                        [disabled]="selectedSurfaces.length < 1" 
                        (click)="onSurfaceDelete()"
                        mat-button>删除
                    </button>
                </div>
            </div>
        </app-table-control>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class = "header-text">
                已有井信息
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-spinner *ngIf="isLoading$ | async" class="progress" diameter="30"></mat-spinner>
        <app-table-control class = "well-table"[dataColumns]="wellColumns" [showPopUp]="true" [dataSource]="wellSource" (selected)="onWellSelect($event)">
            <div class="control">
                <button 
                    class="blue-text" 
                    [disabled]="selectedWells.length < 1" 
                    (click)="onWellDelete()" 
                    mat-button>删除选中井轨迹
                </button>
            </div>
        </app-table-control>
    </mat-expansion-panel>

    <mat-expansion-panel class="computation-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                输入运算参数
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-computation-input [planId]="planId" [projectId]="projectId"></app-computation-input>
    </mat-expansion-panel>
</mat-accordion>