<div *ngIf="plans" fxLayout>
    <div fxLayout fxFlex="80">
        <div class="plan-container">
            <button mat-raised-button class="plan-button" *ngFor="let plan of plans; let i = index" (click)="onSelectPlan(i)" [disabled]="currentPlanIndex === i">{{plan.name}}</button>
        </div>
        <button mat-raised-button class="plan-create" (click)="onCreatePlan()">
            <mat-icon svgIcon="plus"></mat-icon>
            <span>创建方案</span>
        </button>
        <button mat-raised-button class="plan-compare" (click)="onComparePlan()">
            <mat-icon svgIcon="balance"></mat-icon>
            <span>方案对比</span>
        </button>
    </div>
    <div class="plan-container" fxLayout fxFlex fxLayoutAlign="end center" >
        <mat-slide-toggle
            class="best-toggle"
            [color]="themePalette"
            [(ngModel)]="isAutoBestPlan"
            (change)="onAutoBestToggleChange($event)">
            方案自动优选
        </mat-slide-toggle>
        <button mat-raised-button [disabled]="plans[currentPlanIndex].isBest || isAutoBestPlan" [ngClass]="plans[currentPlanIndex].isBest ? 'best-button' : ''" (click)="onBestPlan()">
            <mat-icon svgIcon="star"></mat-icon>
            <span>最优方案</span>
        </button>
        <button mat-raised-button class="plan-tool" (click)="onEditPlan()">
            <mat-icon svgIcon="edit-2"></mat-icon>
            <span>重命名</span>
        </button>
        <button mat-raised-button class="plan-delete" [disabled]="plans[currentPlanIndex].isBest || plans.length <= 1" (click)="onDeletePlan()">
            <mat-icon svgIcon="delete"></mat-icon>
        </button>
    </div>
</div>