import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import "color-legend-element";
import { ColorItemModel } from 'src/app/shared/models/colors/color-item.model';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';

@Component({
  selector: 'app-color-bar-dialog',
  templateUrl: './color-bar-dialog.component.html',
  styleUrls: ['./color-bar-dialog.component.scss']
})
export class ColorBarDialogComponent implements OnInit {

  get isValid(): boolean {
    let errorCount = 0;
    for (let i = 0; i < this.data.length; i++) {
      if (i === 0) {
        errorCount += this.data[i].value < this.data[i + 1].value ? 0 : 1;
      } else if (i === this.data.length - 1) {
        errorCount += this.data[i].value > this.data[i - 1].value ? 0 : 1;
      } else {
        errorCount += this.data[i].value > this.data[i - 1].value && this.data[i].value < this.data[i + 1].value ? 0 : 1;
      }
    }
    return errorCount === 0;
  }

  constructor(
    public dialogRef: MatDialogRef<ColorBarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ColorItemModel[],
  ) {
  }

  async ngOnInit() {
    
  }

  onSave() {
    this.dialogRef.close({ isCompleted: true, data: this.data } as DialogStateModel<ColorItemModel[]>) 
  }
  onClose() {
    this.dialogRef.close({ isCompleted: true, btnType: 'close' } as DialogStateModel<ColorItemModel[]>) 
  }
}
