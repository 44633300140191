import { Pipe, PipeTransform } from '@angular/core';
import { UnitType } from '../enums/unit-type.enum';
 
@Pipe({
    name: 'unitConverter'
})
export class UnitConverterPipe implements PipeTransform {
  transform(value: number, type: UnitType): number {
    let fixed = 0;
    let divider = 1;
    switch (type){
        case UnitType.Money: {
            divider = 10000;
            fixed = 1;
        } break;
        case UnitType.Gas: {
            divider = 10000;
            fixed = 2;
        } break;
    }
    return parseFloat((value/divider).toFixed(fixed));
  }
}