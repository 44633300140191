<mat-dialog-content>
    <div class="wrapper">
        <div *ngFor="let item of data;" class="list-item">
            <ngx-colors class="color-button" ngx-colors-trigger [(ngModel)]="item.hex"></ngx-colors>
            <mat-form-field class="input-field" slbFormField>
                <input #input type="number" [(ngModel)]="item.value" placeholder="" matInput required />
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="wrap-line" align="flex">
    <button mat-button [disabled]="!isValid" (click)="onSave()" cdkFocusInitial>保存</button>
    <button mat-button (click)="onClose()">关闭</button>
</mat-dialog-actions>