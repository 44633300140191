import { SelectedFormulaType } from "../../enums/formula-type.enum";
import { StrategyType } from "../../enums/wells/strategy-type.enum";
import { ExperienceFormulaModel } from "./experience-formula.model";
import { TheoryFormulaModel } from "./theory-formula.model";

export class DeflatedRadiusModel {
    surfaceId: string;
    surfaceName: string;
    horizontalRecoveryEfficiency = 0.7;
    verticalRecoveryEfficiency = 0.7;
    horizontalWellLength = 1200;
    horizontalWellWidth = 500;
    length = 1000;
    riverAngle = 180;
    principalStress = 70;
    selectedFormulaType = SelectedFormulaType.ExperienceFormula;
    experienceFormula = new ExperienceFormulaModel();
    theoryFormula = new TheoryFormulaModel();
}