<mat-accordion class="example-headers-align" multi="true">
    <mat-expansion-panel expanded ="true"  class="well-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                平台推荐井
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-control  
            class = "well-table"
            [dataColumns]="wellColumns" 
            [dataSource]="generatedWellSource"
            [showPopUp]="true"
            [showTotal]="true"
            [showFilter]="true"
            (selected)="onWellSelect($event)">
            <div>
                <button class="blue-text" [disabled]="selectedWells.length < 1" (click)="onWellDelete()" mat-button>删除所选井轨迹</button>
                <button class="blue-text" [disabled]="!isExportAvailable" (click)="exportCSV()" mat-button>导出单井储量表</button>
                <button class="blue-text" [disabled]="!isExportAvailable" (click)="exportWellHeadCSV()" mat-button>导出井头信息</button>
                <button class="blue-text" [disabled]="!isExportAvailable" (click)="exportRadiusCSV()" mat-button>导出泄气半径信息表</button>
                <button class="blue-text" [disabled]="selectedWells.length < 1" (click)="exportWellTrajectory()" mat-button>导出井轨迹文件</button>
            </div>
        </app-table-control>
    </mat-expansion-panel>
</mat-accordion>