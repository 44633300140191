export const LEGEND_CONFIG = {
    top: '5%',
    left: 'center',
    data: [
        {
            name: '已有井',
            itemStyle: {
                color: 'black',
                borderColor: 'black',
            }
        },
        {
            name: '平台推荐水平井',
            itemStyle: {
                color: 'red',
                borderColor: 'red',
            }
        },
        {
            name: '平台推荐直定井',
            itemStyle: {
                color: 'rgb(192, 192, 192)',
                borderColor: 'rgb(192, 192, 192)',
            }
        },
        {
            name: '靶点',
            itemStyle: {
                color: 'orange'
            }
        }]
};


export const LEGEND_LABLES = [
/*
    {
    type: 'line3D',
    //legend mark for exsisting well
    name: "已有井",
},
{
    type: 'line3D',
    //legend mark for horizontal well
    name: "平台推荐水平井",
},
{
    type: 'line3D',
    //legend mark for vertical well
    name: "平台推荐直定井",
},
{
    type: 'scatter3D',
    //legend mark for  targetPoints
    name: "靶点",
}*/
]