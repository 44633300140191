<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" mat-dialog-title class="title-content">
    <h5>
        <strong> 泄气半径公式 </strong>
        <slb-modal-close-button
        [dialogRef]="dialogRef"
        (beforeClose)="onClose($event)"
        buttonType="Icon"
        ></slb-modal-close-button>
    </h5>
</div>
<div mat-dialog-content>
    <div class = "dialog-size">
        <div class = "label-text">延长泄气半径经验公式 </div>
        <img class = "image"  src="../../../../../assets/experience-formula.png " alt="Placeholder " />
        <br>
        <br>
        <div class = "label-text" >泄气半径理论推导公式 </div>
        <img class = "image" src="../../../../../assets/theory-formula.png " alt="Placeholder " />
        <br>
        <div class = "label-text" >机器学习模型</div>
        <div class = "label-content" >本软件对比了神经网络、随机森林等多种机器学习模型, 最终采用了集成学习模型中的随机森林模型。模型对比详见研究报告 </div>
    </div>
</div>
<div>
    <button class="confirm-button"
    [mat-dialog-close]="">
    关闭
    </button>
</div>
