import { Component, Input } from '@angular/core';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';

@Component({
  selector: 'app-economic-parameter',
  templateUrl: './economic-parameter.component.html',
  styleUrls: ['./economic-parameter.component.scss']
})
export class EconomicParameterComponent {

    @Input()
    model: ComputationModel;

    isDetailedParameter = false;

    onExpandClick() {
      this.isDetailedParameter = !this.isDetailedParameter;
    }
}
