import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GiipModel } from 'src/app/shared/models/surfaces/giip.model';
import { GeologicalParaModel } from 'src/app/shared/models/surfaces/surface-dialog-data.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  @Input() ifReload: boolean;

  constructor() {}  
  @Output()
  onUpload = new EventEmitter<File[]>();

  @Output()
  onSelect = new EventEmitter<GeologicalParaModel[]>();

  ngOnChanges() {
    if (this.ifReload){
      this.selectedFiles = []
    }
  } 

  selectedFiles: File[] = [];
  geologicalParaModel: GeologicalParaModel[] = [];
  DEFAULT_GEOLOGICAL_PARAMETER = 0.005

  onFilesSelected(files: File[]) {
    this.geologicalParaModel = []
    this.selectedFiles = files;
    for (const suefaceFile of this.selectedFiles) {
      this.geologicalParaModel.push( {
        surfaceName: suefaceFile.name,
        giipModel: {geologicalParameter: this.DEFAULT_GEOLOGICAL_PARAMETER} as GiipModel,
        surfaceFile: suefaceFile
      } as GeologicalParaModel);
    }
    this.onSelect.emit(this.geologicalParaModel)
  }

  onFilesUpload(): void {
    this.onUpload.emit(this.selectedFiles);
  }
}
