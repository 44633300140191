import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ColorCreateEditModel, ColorUpdateModel } from 'src/app/shared/models/colors/color-create-edit.model';
import { ColorItemModel } from 'src/app/shared/models/colors/color-item.model';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExtraService {
  private apiUrl = environment.baseUrl + '/api/extra';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  constructor(private http: HttpClient) { }

  saveColors(data: ColorCreateEditModel): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/color`;
    return this.http.post<ResultEmptyModel>(url, data);
  }

  getColors(projectId: string, type: string): Observable<ResultModel<ColorItemModel[]>> {
    const url = `${this.apiUrl}/color?projectId=${projectId}&type=${type}`;
    return this.http.get<ResultModel<ColorItemModel[]>>(url);
  }
  updateColors(data: ColorUpdateModel): Observable<ResultModel<ColorItemModel[]>> {
    const url = `${this.apiUrl}/updateColor`;
    return this.http.post<ResultModel<ColorItemModel[]>>(url, data);
  }
}
