import { RadioButtonValue } from "@slb-dls/angular-material/radio-button-group";
import { SelectedAlgoType } from "src/app/shared/enums/algo-type.enum";
import { SelectedDistanceType } from "src/app/shared/enums/wells/distance-type.enum";
import { StrategyType } from "src/app/shared/enums/wells/strategy-type.enum";
import { SelectedFormulaType } from "../../../shared/enums/formula-type.enum";

export const AlgoOptionsRadioButtonInfo: RadioButtonValue[] = [
  {
    value: SelectedAlgoType.InteligentWellPlanning,
    name: '人工智能化差异布井',
    isDefault: true,
  },
  {
    value: SelectedAlgoType.EqualDistance,
    name: '等间距排式布井',
  },
  {
    value: SelectedAlgoType.DeepLearning,
    name: '机器学习布井',
    isDisabled: true
  }
];

export const FixWellSpaceRadioButtonInfo: RadioButtonValue[] = [
  {
    value: SelectedDistanceType.FixedWellSpace,
    name: '均匀井距',
    isDefault: true,
  },
  {
    value: SelectedDistanceType.FixedWellDensity,
    name: '固定井网密度',
    isDisabled: true
  },
];

export const StrategyRadioButtonInfo: RadioButtonValue[] = [
  {
    value: StrategyType.MaxOil,
    name: '最大控制储量',
    isDefault: true,
  },
  {
    value: StrategyType.MaxMoney,
    name: '最高经济效益',
    isDisabled: true
  },
];

export const DistributionOptionsRadioButtonInfo: RadioButtonValue[] = [
  {
    value: true,
    name: '均匀分布',
    isDefault: true,
  },
  {
    value: false,
    name: '三角分布',
    isDisabled: true
  },
  {
    value: false,
    name: '正态分布',
    isDisabled: true
  }
];

export const FormulasOptionsRadioButtonInfo: RadioButtonValue[] = [
  {
    value: SelectedFormulaType.ExperienceFormula,
    name: '延长泄气半径经验公式',
    isDefault: true,
  },
  {
    value: SelectedFormulaType.TheoryFormula,
    name: '泄气半径理论推导公式',
  }
];

export const DLS = 5
export const MAXDEPTH = 3000
export const INITIALPOINT = 500;
export const FORMULAA = 1009.8
export const FORMULAB = 0.2794
export const FORMULAC = 0.035
export const FORMULAM = 0.02
export const FORMULAT = 2
export const RIVERDEGREE = 180
export const PRINCEPALSTRESS = 180
export const HORIZONTALLENGTH = 1200
export const HORIZONTALWIDTH = 500
export const LENGTH = 1000
export const VERTICALCOST = 1000
export const COMMODITYRATE = 0.9
export const GASMIN = 100
export const GASMAX = 200
export const RATEMIN = 0.3
export const RATEMAX = 0.9