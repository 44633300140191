import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from 'src/app/material/material.module';
import { ProjectDashbordComponent } from './project-dashbord/project-dashbord.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { MatGridListModule } from '@angular/material/grid-list';
import { ProjectTableComponent } from './project-table/project-table.component';
import { ProjectDialogComponent } from './project-dialog/project-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ProjectDashbordComponent,
    ProjectTableComponent,
    ProjectDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    AgGridModule.withComponents([]),
    MatGridListModule
  ]
})
export class LandingModule { }
