import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';
import { Points } from 'src/app/shared/models/points.model';
import { ProjectWorkerModel } from 'src/app/shared/models/computation/project-worker.model';
import { CalculationModel } from 'src/app/shared/models/computation/calculation.model';
import { WorkerType } from 'src/app/shared/enums/workers/worker-type.enum';
import { environment } from 'src/environments/environment';
import { DeflatedRadiusModel } from 'src/app/shared/models/computation/deflated-radius.model';

@Injectable({
    providedIn: 'root'
})
export class ComputationService {
    private apiUrl = environment.baseUrl +'/api/computation';

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    private subject$ = new Subject();

    constructor(private http: HttpClient) { }

    getRefreshDataSubjectEvent() {
        return this.subject$.asObservable();
    }
  
    addRefreshDataSubjectEvent() {
        this.subject$.next();
    }

    saveComputationParameter(inputData: ComputationModel, planId: string): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/parameter?planId=${planId}`;
        return this.http.post<ResultEmptyModel>(url, inputData);
    }

    getComputationParameter(planId: string): Observable<ResultModel<ComputationModel>> {
        const url = `${this.apiUrl}/parameter?planId=${planId}`;
        return this.http.get<ResultModel<ComputationModel>>(url);
    }

    getDeflatedRadius(planId: string,surfaceId:string): Observable<ResultModel<DeflatedRadiusModel>> {
        const url = `${this.apiUrl}/parameter/radius/?planId=${planId}&surfaceId=${surfaceId}`;
        return this.http.get<ResultModel<DeflatedRadiusModel>>(url);
    }

    getStatus(planId: string, workerType: WorkerType): Observable<ResultModel<ProjectWorkerModel>> {
        const url = `${this.apiUrl}/status?planId=${planId}&workerType=${workerType}`;
        return this.http.get<ResultModel<ProjectWorkerModel>>(url);
    }

    calculateData(model: CalculationModel): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/calculate`;
        return this.http.post<ResultEmptyModel>(url, model);
    }

    /** Upload */
    upload(filedata: FormData, projectId: string): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/upload?projectId=${projectId}`;
        return this.http.post<ResultEmptyModel>(url, filedata);
    }

    /** Retrieve Target Points */
    getGeneratedTargetPoints(surfaceId: string, planId: string): Observable<ResultModel<Points>> {
        let isExsisting = false;
        const url = `${this.apiUrl}/targetPoint/list?surfaceId=${surfaceId}&planId=${planId}&isExsisting=${isExsisting}`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`get target points`)),
            catchError(this.handleError<string>(`get target points failed`))
        );
    }


        /** Retrieve Target Points */
    getExsistingTargetPoints(surfaceId: string, planId: string): Observable<ResultModel<Points>> {
        let isExsisting = true;
        const url = `${this.apiUrl}/targetPoint/list?surfaceId=${surfaceId}&planId=${planId}&isExsisting=${isExsisting}`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`get target points`)),
            catchError(this.handleError<string>(`get target points failed`))
        );
    }
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            console.error(error); // log to console instead

            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}
