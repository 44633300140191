
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { SlbDropdownOption } from '@slb-dls/angular-material/dropdown';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComputationService } from 'src/app/core/services/computation.service';
import { ExtraService } from 'src/app/core/services/extra.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { WellService } from 'src/app/core/services/well.service';
import { TargetPointType } from 'src/app/shared/enums/targetpoint-type.enum';
import { WellType } from 'src/app/shared/enums/wells/well-type.enum';
import { ColorCreateEditModel, ColorUpdateModel } from 'src/app/shared/models/colors/color-create-edit.model';
import { ColorItemModel } from 'src/app/shared/models/colors/color-item.model';
import { GraphData } from 'src/app/shared/models/graph-data.model';
import { Points } from 'src/app/shared/models/points.model';
import { ShaleGas } from 'src/app/shared/models/shale-gas.model';
import { SurfaceInfoModel } from 'src/app/shared/models/surfaces/surface-info.model';
import { SurfaceType } from 'src/app/shared/models/surfaces/surface-type.model';
import { SurfaceModel } from 'src/app/shared/models/surfaces/surface.model';
import { ZDepthModel } from 'src/app/shared/models/surfaces/zdepth.model';
import { WellInfoModel } from 'src/app/shared/models/wells/well-info.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';
import { LEGEND_CONFIG, LEGEND_LABLES } from './graph.constants';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponent implements OnInit, OnDestroy {

  @Input()
  projectId: string;

  @Input()
  planId: string;

  @Input()
  wellSource: WellModel[] = [];

  @Input()
  generatedWellSource: ShaleGas[] = [];  

  @Input()
  surfaceSource: SurfaceModel[] = [];

  private graphData = new GraphData();

  zScales: SlbDropdownOption[] = [
    ({ viewText: '1', value: 1 }) as SlbDropdownOption,
    ({ viewText: '10', value: 10 }) as SlbDropdownOption,
    ({ viewText: '100', value: 100 }) as SlbDropdownOption,
    ({ viewText: '1000', value: 1000 }) as SlbDropdownOption,
    ({ viewText: '10000', value: 10000 }) as SlbDropdownOption,
    ({ viewText: '100000', value: 100000 }) as SlbDropdownOption,
    ({ viewText: '1000000', value: 1000000 }) as SlbDropdownOption,
    ({ viewText: '10000000', value: 10000000 }) as SlbDropdownOption,
  ];
  selectedZScales = this.zScales.find(a => a.value === 1);

  surfaceOptions: SlbDropdownOption[] = [
    ({ viewText: '孔隙度', value: 'porosity' }) as SlbDropdownOption,
    ({ viewText: '渗透率', value: 'permeability' }) as SlbDropdownOption,
    ({ viewText: '饱和度', value: 'gasSaturation' }) as SlbDropdownOption,
    ({ viewText: '储量图', value: 'giip' }) as SlbDropdownOption,
    ({ viewText: '有效砂体厚度', value: 'sand' }) as SlbDropdownOption,
  ];
  selectedOption = this.surfaceOptions.find(a => a.value === 'giip');
  
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  colors$: BehaviorSubject<ColorItemModel[]> = new BehaviorSubject([]);
  
  toggleColor:ThemePalette = 'warn';
  showPoints = false;
  showRadius = false;
  isOverlook = false;
  colorBarData = [];

  zLayerDepth: ZDepthModel[] =[];
  isShortFormat = false;

  series = [];
  initOption = ({
    tooltip: {},
    xAxis3D: {
      type: 'value',
      scale: true,
    },
    yAxis3D: {
      type: 'value',
      scale: true,
    },
    zAxis3D: {
      type: 'value',
      scale: true,
    },
    light: {
      main: {
        shadow: true,
        intensity: 2
      },
    },
    grid: {
      tooltip: {
        show: true,
        trigger: 'axis'
      }
    },
    grid3D: {
      // viewControl: {
      //   alpha: 90,
      //   beta: 0,
      // }
    },
    legend: LEGEND_CONFIG,
    series: LEGEND_LABLES
  });
  chartInstance;

  computationSubscription: Subscription;

  constructor(
    private surfaceService: SurfaceService,
    private extraService: ExtraService,
    private wellService: WellService,
    private computationService: ComputationService,
    private messageService: MessageService,
  ) {
   
  }

  onChartInit(e: any) {
    this.chartInstance = e;
  }

  async ngOnInit() { 
    console.log('init_', this.colors$.value)
    
    this.extraService.getColors(this.projectId, this.selectedOption.value).subscribe(res => {
      this.colorBarData = res.data
      this.colors$.next(this.colorBarData)
    })
    this.computationSubscription = this.computationService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getCalculatedPoints();
      this.showPoints = true;
      this.onlyDrawTargetPoints();
      this.isLoading$.next(false);
    })
  }

  ngOnDestroy(): void {
    this.computationSubscription.unsubscribe()
  }

  bindTrailingArgs = (fn, ...bound_args) => {
    return (...args) => {
      return fn(...args, ...bound_args);
    };
  }

  getSurfaceSeries(surfaces: SurfaceInfoModel[], surfaceType: SurfaceType): any[] {
    const seriesDatas = [];
    this.zLayerDepth = [];
    for (const surface of surfaces) {
      let zDepth = -this.getMin(surface.data.map(a => Math.abs(a.z)))
      console.log(zDepth)
      this.zLayerDepth.push({
        surfaceName: surface.name,
        zDepth: zDepth
      })
      const minVal = this.getMin(surface.data.map(el => el[surfaceType]))
      const maxVal = this.getMax(surface.data.map(el => el[surfaceType]))
      const displayData = surface.data.map(el => [el.x, el.y, zDepth, el[surfaceType]]);
      const seriesData = {
        data: displayData,
        type: 'surface',
        wireframe: {show: false},
        name: surface.name,
        itemStyle: {
          color: this.bindTrailingArgs(this.colorFunc, this,  minVal, maxVal),
        },
      };
      seriesDatas.push(seriesData);
    }
    return seriesDatas;
  }

  getWellSeries(wells: WellInfoModel[]): any[] {
    const series = []
    for (const well of wells) {
      if (well.isExisting){
        this.addWellsToEchart(series, well, 'black')
      }
      else{
        if (well.wellType === WellType.Horizontal){
          this.addWellsToEchart(series, well, 'red')
        }
        else{
          this.addWellsToEchart(series, well, 'blue')
        }
      }
    }
    return series;
  }

  getPointSeries(points: Points[], zDepth: ZDepthModel[]): any[] {
    const series = []
    let i = 0;

    for (const point of points) {
      series.push({
        data: point.data.filter(d=>d.targetType==TargetPointType.Vertical).map(d => 
          ({
            value: [d.x, d.y, zDepth[i].zDepth+.02],
            itemStyle: {
              color:'black',         
              opacity: 1,
            }
          })
        ),
        symbolSize: 8,
        type: 'scatter3D',
        name: this.surfaceSource.find(a => a.id == point.surfaceId).name, 
      });

      point.data.filter(d=>d.targetType==TargetPointType.Horizontal).forEach(d=>{
        series.push({
          data: [[d.x, d.y, zDepth[i].zDepth+.1 ],[d.xEnd, d.yEnd, zDepth[i].zDepth+.02] ],
          name: this.surfaceSource.find(a => a.id == point.surfaceId).name,  
          type: 'line3D',  
          lineStyle: {
            color:'black',         
            opacity: 1,
            width: 3          
          },
        })
      })
      i++;
    }
    return series;
  }


  getExsistingPointSeries(points: Points[], zDepth: ZDepthModel[]): any[] {
    const series = []
    let i = 0;
    for (const point of points) {
      series.push({
        data: point.data.filter(d=>d.targetType==TargetPointType.Vertical).map(d => 
              [d.x, d.y, zDepth[i].zDepth+.02]
          ),
        type: 'scatter3D',
        name: this.surfaceSource.find(a => a.id == point.surfaceId).name,  
        symbol: 'rect',
        symbolSize: 6,
        itemStyle: {
          color:'grey',         
          opacity: 0.3,
          borderWidth: 0.1
        },
      });
      i++;
    }
    return series;
  }

  getPointRadiusSeries(points: Points[], zDepth: ZDepthModel[]): any[] {
    const series = []
    let i = 0;
    for (const point of points) {
      point.data.filter(d => d.targetType == TargetPointType.Vertical).forEach(data => {
        series.push({
          data: this.getCircle(data.r, 50, data.x, data.y, zDepth[i].zDepth + .02),
          name: this.surfaceSource.find(a => a.id == point.surfaceId).name,  
          type: 'line3D',
          lineStyle: {
            width: 3,
            color: 'red',
            opacity: 1
          }
        })
      });
      i++;
    }
    return series;
  }

  async getCalculatedPoints(){
    this.graphData.points = [];
    for (let surface of this.surfaceSource) {
      let generatedPoints = await this.computationService.getGeneratedTargetPoints(surface.id, this.planId).toPromise()
      if (generatedPoints.data){
        this.graphData.points.push(generatedPoints.data)
      }
    }
  }

  async getExsistingPoints(){
    this.graphData.exsistingPoints = [];
    for (let surface of this.surfaceSource) {
      let existingpPoints = await this.computationService.getExsistingTargetPoints(surface.id, this.planId).toPromise()
      if (existingpPoints.data){
        this.graphData.exsistingPoints.push(existingpPoints.data)
      }
    }
  }

  async onDraw() {
    this.isLoading$.next(true);
    this.graphData = new GraphData();

    for (let surface of this.surfaceSource){
      this.graphData.surfaces.push((await this.surfaceService.getSurface(surface.name, this.projectId).toPromise()).data);
    }
    for (let well of this.wellSource){
      this.graphData.wells.push((await this.wellService.get(well.id).toPromise()).data);
    }
    for (let well of this.generatedWellSource){
      this.graphData.wells.push((await this.wellService.get(well.id).toPromise()).data);
    }
    
    if(this.showPoints){
      await this.getCalculatedPoints();
      await this.getExsistingPoints();
    }

    if(this.showRadius){
      await this.getCalculatedPoints();
      await this.getExsistingPoints();
    }

    if(this.isOverlook) {
      this.initOption.grid3D = {
        viewControl: {
          alpha: 90,
          beta: 0,
        }
      };
    } else {
      this.initOption.grid3D = {
        viewControl: {
          alpha: 40,
          beta: 40,
        }
      };
    }

    this.displayGraphData();
    this.isLoading$.next(false);
  }

  async onlyDrawTargetPoints() {
    this.isLoading$.next(true);
    this.graphData = new GraphData();
    for (let surface of this.surfaceSource){
      this.graphData.surfaces.push((await this.surfaceService.getSurface(surface.name, this.projectId).toPromise()).data);
    }
    if(this.showPoints){
      await this.getCalculatedPoints();
      await this.getExsistingPoints();
    }
    this.displayGraphData();
    this.isLoading$.next(false);
  }

  onColorChanged(data: ColorItemModel[]) {
    this.extraService.saveColors({
        projectId: this.projectId,
        type: this.selectedOption.value,
        items: data
    } as ColorCreateEditModel).subscribe(
        res => {
          this.messageService.add({ severity: SlbSeverity.Success, detail: "新色尺成功保存", summary: '保存色尺', asHtml: false, target: 'toast' });
        },
        res => this.messageService.add({ severity: SlbSeverity.Error, detail: res.error.message, summary: '保存色尺', asHtml: false, target: 'toast' })
    )
  }
  async UpdateColorBar() {
    const selectedIds = this.surfaceService.selectedSurfaceIds
    if(!selectedIds.length) return
    
    this.surfaceService.colorBarTickFormat = this.selectedOption.value
    switch(this.selectedOption.value) {
      case 'giip': 
        this.surfaceService.colorBarTickFormat = '.0f';
        break;
      default: 
        this.surfaceService.colorBarTickFormat = '.3f';
    }
    this.colors$.next([])
    return new Promise((resolve, reject) => {
      this.extraService.updateColors({
        projectId: this.projectId,
        type: this.selectedOption.value,
        surfaceIds: selectedIds
      } as ColorUpdateModel).subscribe(
        res => {
          this.colorBarData = res.data
          console.log('UpdateColorBar调用成功')
          resolve(this.colors$.next(this.colorBarData))
        }
      )
    })
    
    
  }

  displayGraphData(): void {
    this.UpdateColorBar().then(res => {
      const surfaces = this.getSurfaceSeries(this.graphData.surfaces, this.selectedOption.value);
      const wells = this.getWellSeries(this.graphData.wells);
      
      this.series = [];
  
      this.series.push(...surfaces);
      this.series.push(...wells);
      
  
      if (this.showPoints){
        const generatedpPoints = this.getPointSeries(this.graphData.points, this.zLayerDepth);
        const exsistingPoints = this.getExsistingPointSeries(this.graphData.exsistingPoints, this.zLayerDepth);
        this.series.push(...generatedpPoints);
        this.series.push(...exsistingPoints);
      }
      if (this.showRadius) {
        const pointRadius = this.getPointRadiusSeries(this.graphData.points, this.zLayerDepth);
        this.series.push(...pointRadius);
      }
  
      LEGEND_LABLES.forEach(lable => this.series.push(lable))
  
      // if (this.graphData.surfaces[0]) {
      //   this.initOption.xAxis3D = this.getAxis3D("x", this.graphData.surfaces) // take any of the existing surfaces
      //   this.initOption.yAxis3D = this.getAxis3D("y", this.graphData.surfaces)
      // }
      this.initOption.series = this.series;
  
      this.setZscale();
      this.chartInstance.setOption(this.initOption, true);

    })
  }

  private getAxis3D(type: String, surfaces: SurfaceInfoModel[]) {
    let axis = {
      type: 'value',
      scale: true,
    } as any;
    var xMin = Math.min(...surfaces.map(s => s.location[0].x));
    var xMax = Math.max(...surfaces.map(s => s.location[2].x));
    var yMin = Math.min(...surfaces.map(s => s.location[0].y));
    var yMax = Math.max(...surfaces.map(s => s.location[2].y));
    console.log(xMax,xMin,yMax,yMin)
    switch (type.toUpperCase()) {
      case 'X': {
        axis.min =  xMin-10000
        axis.max = xMax+10000
      } break;
      case 'Y': {
        axis.min = yMin
        axis.max = yMax
      } break;
    }
    return axis;
  }

  onZscaleChange($event: any): void {
    this.displayGraphData();
  }

  private addWellsToEchart(series: Array<Object>, well: WellInfoModel, color: string){
    if (this.selectedZScales['value'] == -1){
      this.isShortFormat = true;
    }
    let data = well.data.map(d => [d.x, d.y, d.z])
    series.push({
      data: this.isShortFormat ? data.filter(a => a[2] <= -499) : data,
      type: 'line3D',
      name: well.name,
      lineStyle: {
           color: color,
           width: 2,
           label: {
            show: true,
            position: 'top',
            
         }},
    });
    return this.series
  }

  private colorFunc(params, thisParam, minVal = 0, maxVal = 100,): string {
    const diff = maxVal - minVal;
    const colorVal = params.data[3];

    if (colorVal === null || colorVal === thisParam.valueErrorIndicator) {
      // Unknown value
      return 'white';
    }
    
    return thisParam.colorFuction(colorVal, minVal, diff)
  }

  private colorFuction(colorVal: number, minVal: number, diff: number): string {
    const scaledVal = colorVal;
    const colors = this.colorBarData;
    // surfaceService
    let result = 'white';
    for (let i = 0; i < colors.length; i++){
      if (scaledVal < colors[0].value) {
        result = colors[0].hex;
        break;
      } else if (scaledVal >= colors[colors.length - 1].value) {
        result = colors[colors.length - 1].hex;
        break;
      } else if (scaledVal >= colors[i].value && scaledVal < colors[i + 1].value) {
        result = colors[i + 1].hex;
        break;
      }
    }
    return result;
  }

  private getMax(arr: number[]): number {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
      if (arr[len] > 0) {
        max = arr[len] > max ? arr[len] : max; 
      }
    }
    return max;
  }

  private getMin(arr: number[]): number {
    let len = arr.length;
    let min = +Infinity;

    while (len--) {
      if (arr[len]> 0) {
        min = arr[len] < min ? arr[len]: min;
      }
    }
    return min;
  }

  private getCircle(radius: number, steps: number, centerX: number, centerY: number, centerZ: number){
    let result = [];
    for (var i = 0; i < steps; i++) {
      result.push(
        [
          centerX + radius * Math.cos(2 * Math.PI * i / steps), 
          centerY + radius * Math.sin(2 * Math.PI * i / steps), 
          centerZ
        ]
      )
    }
    result.push(result[0])
    return result;
  }

  private setZscale(){
    this.initOption.zAxis3D['minInterval'] = this.selectedZScales['value']
    if (this.selectedZScales['value'] ===10000)
      {
        this.initOption.zAxis3D['min'] = -this.selectedZScales['value']
        this.initOption.zAxis3D['max'] = this.selectedZScales['value']
      }
    else{
      this.initOption.zAxis3D['min'] = null
      this.initOption.zAxis3D['max'] = null
    }
  }
}
