import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '@slb-dls/angular-material/notification';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ComputationService } from 'src/app/core/services/computation.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { SelectedAlgoType } from 'src/app/shared/enums/algo-type.enum';
import { SelectedFormulaType } from 'src/app/shared/enums/formula-type.enum';
import { SelectedDistanceType } from 'src/app/shared/enums/wells/distance-type.enum';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';
import { AlgoOptionsRadioButtonInfo, FixWellSpaceRadioButtonInfo, FormulasOptionsRadioButtonInfo, StrategyRadioButtonInfo } from '../../component-info.constants';
import { FormulaImageComponent } from '../../popup/formula-image/formula-image.component';
import { MlPopupComponent } from '../../popup/ml-popup/ml-popup.component';
import { WellImageComponent } from '../../popup/well-image/well-image.component';

@Component({
  selector: 'app-main-parameter',
  templateUrl: './main-parameter.component.html',
  styleUrls: ['./main-parameter.component.scss']
})
export class MainParameterComponent {

  @Input()
  model: ComputationModel;

  SelectedAlgoType = SelectedAlgoType;
  SelectedFormulaType = SelectedFormulaType;
  SelectedDistanceType = SelectedDistanceType;

  algoOptions: RadioButtonValue[] = AlgoOptionsRadioButtonInfo;
  strategyOptions: RadioButtonValue[] = StrategyRadioButtonInfo;
  fixWellSpaceOptions: RadioButtonValue[] = FixWellSpaceRadioButtonInfo;
  formulasOptions: RadioButtonValue[] = FormulasOptionsRadioButtonInfo;
  
  isOpened = false;

  _dialogWellImage: MatDialogRef<WellImageComponent, any> = null;
  _dialogPopup: MatDialogRef<MlPopupComponent, any> = null;
  _dialogFormulaImage: MatDialogRef<FormulaImageComponent, any> = null;

  constructor(
    private computationService: ComputationService,
    private planService: PlanService,
    private messageService: MessageService,
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {
    if (this._dialogPopup) {
      this._dialogPopup.close();
      this._dialogPopup = null;
    }
  }

  onAlgoOptionChanged(option: RadioButtonValue) {
    if (option.value === SelectedAlgoType.DeepLearning) {
      this.openDialog()
    }
  }

  openDialog(): void {
    if (this._dialogPopup) {
      this._dialogPopup.close();
    }
    this._dialogPopup = this._createDialog();
  }

  private _createDialog(): MatDialogRef<MlPopupComponent, any> {
    const dialogRef = this.dialog.open(MlPopupComponent, {
      disableClose: true,
      data: {
      },
    });

    dialogRef.afterOpened().subscribe(() => this.isOpened = true);
    dialogRef.beforeClosed().subscribe(result => {
      this.isOpened = false;
      this._dialogPopup = null;
    });

    return dialogRef;
  }
}
