<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" mat-dialog-title class="title-content">
    <h5>
        <strong> 水平井示意图 </strong>
        <slb-modal-close-button
        [dialogRef]="dialogRef"
        (beforeClose)="onClose($event)"
        buttonType="Icon"
        ></slb-modal-close-button>
    </h5>
</div>
<div class = "dialog-size" mat-dialog-content>
    <div class = "dialog-size">
    <img class="well-image " src="../../../../../assets/well.png " alt="Placeholder " />
    </div>
</div>
<div>
    <button class="confirm-button"
    [mat-dialog-close]="">
    关闭
    </button>
</div>
