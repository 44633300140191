import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService, SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { ProjectService } from 'src/app/core/services/project.service';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { ProjectInfoModel } from 'src/app/shared/models/projects/project-info.model';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';

@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['./project-table.component.scss'],
})
export class ProjectTableComponent implements OnInit {

    @Output()
    projectSelect = new EventEmitter<ProjectInfoModel>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource = new MatTableDataSource<ProjectInfoModel>();

    displayedColumns: string[] = ['name', 'field', 'existingWellCount', 'generatedVerticalAndDeviatedWellCount', 'generatedHorizontalWellCount', 'user', 'modified', 'action'];

    selectedRow: ProjectInfoModel;

    constructor(
        private readonly messageService: MessageService,
        private readonly projectService: ProjectService,
        private readonly router: Router,
        private readonly dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.getProjectList();
    }

    getProjectList() {
        this.projectService.getList().subscribe(result => {
            this.dataSource.data = result.data;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    onClickProject(data: ProjectInfoModel) {
        this.selectedRow = data;
        this.projectSelect.emit(data);
    }

    onOpenProject() {
        this.router.navigate(['/computation'], { queryParams: { projectId: this.selectedRow.id } });
    }

    onCreateProject() {
        const dialogRef = this.dialog.open(ProjectDialogComponent, {
            width: '450px',
            data: {},
        });

        dialogRef.afterClosed().subscribe((result: DialogStateModel<string>) => {
            if (result.isCompleted) {
                this.getProjectList();
            }
        });
    }

    onEditProject(data: ProjectInfoModel) {
        const dialogRef = this.dialog.open(ProjectDialogComponent, {
            width: '450px',
            data: { id: data.id },
        });

        dialogRef.afterClosed().subscribe((result: DialogStateModel<string>) => {
            if (result.isCompleted) {
                this.getProjectList();
            }
        });
    }

    onDeleteProject(data: ProjectInfoModel) {
        const confirmation: SlbMessage = {
            target: 'modal',
            severity: SlbSeverity.Info,
            summary: '确认',
            detail: `是否要删除工区"${this.selectedRow.name}"? 确认后工区将被全部删除`,
            config: {
                primaryAction: '是',
                secondaryAction: '否',
                primaryActionCallback: async (data) => {
                    this.projectService.delete(this.selectedRow.id).subscribe(
                        result => {
                            this.getProjectList();
                            this.selectedRow = null;
                            this.messageService.clear(confirmation);
                            this.messageService.add({ severity: SlbSeverity.Success, detail: "工区成功删除", summary: '删除工区', asHtml: false, target: 'toast' });
                        },
                        result => this.messageService.add({ severity: SlbSeverity.Error, detail: result.error.message, summary: '删除工区', asHtml: false, target: 'toast' })
                    )
                },
                secondaryActionCallback: async (data) => {
                    this.messageService.clear(confirmation);
                },
            },
        };
        this.messageService.add(confirmation);
    }
}