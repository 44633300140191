<h1 mat-dialog-title>
    {{title}}
</h1>
  
<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions>
    <button mat-raised-button class = "confirm-button" (click)="onConfirm()">是</button>
    <button mat-button (click)="onDismiss()">否</button>
</div>
