<!--
 * @Author: jpang6 jpang6@slb.com
 * @Date: 2022-12-22 11:07:55
 * @LastEditTime: 2022-12-22 11:07:55
-->
<div class="container">
    <div>
        <div class="caption">
            <div></div>
            <div>
                <button class="create-button" (click)="onCreateProject()" mat-raised-button>新建工区</button>
                <button class="create-button" (click)="onOpenProject()" [disabled]="!selectedRow"
                    mat-raised-button>打开工区</button>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name" sticky>
                <th scope="col" mat-header-cell *matHeaderCellDef> 名称 </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="field">
                <th scope="col" mat-header-cell *matHeaderCellDef> 区块 </th>
                <td mat-cell *matCellDef="let element"> {{element.field}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="existingPlatformCount">
                <th scope="col" mat-header-cell *matHeaderCellDef> 已有井台 </th>
                <td mat-cell *matCellDef="let element"> {{element.existingPlatformCount}} </td>
            </ng-container> -->
            <ng-container matColumnDef="existingWellCount">
                <th scope="col" mat-header-cell *matHeaderCellDef> 已有井 </th>
                <td mat-cell *matCellDef="let element"> {{element.existingWellCount}} </td>
            </ng-container>
            <ng-container matColumnDef="generatedVerticalAndDeviatedWellCount">
                <th scope="col" mat-header-cell *matHeaderCellDef> 生成直/定井 </th>
                <td mat-cell *matCellDef="let element"> {{element.generatedVerticalAndDeviatedWellCount}} </td>
            </ng-container>
            <ng-container matColumnDef="generatedHorizontalWellCount">
                <th scope="col" mat-header-cell *matHeaderCellDef> 生成水平井 </th>
                <td mat-cell *matCellDef="let element"> {{element.generatedHorizontalWellCount}} </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th scope="col" mat-header-cell *matHeaderCellDef> 用户名 </th>
                <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
            </ng-container>
            <ng-container matColumnDef="modified">
                <th scope="col" mat-header-cell *matHeaderCellDef> 修改时间 </th>
                <td mat-cell *matCellDef="let element"> {{element.dateModify | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
                <th scope="col" mat-header-cell *matHeaderCellDef> 编辑 </th>
                <td mat-cell *matCellDef="let element">
                    <button slb-icon-button [matMenuTriggerFor]="projectMenu" matTooltip="More">
                        <mat-icon svgIcon="more"></mat-icon>
                    </button>
                    <mat-menu #projectMenu="matMenu">
                        <button (click)="onEditProject(element)" mat-menu-item>编辑</button>
                        <button (click)="onDeleteProject(element)" mat-menu-item>删除</button>
                    </mat-menu>
                </td>
            </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row [ngClass]="{ 'selected': row === selectedRow }" (click)="onClickProject(row)" (dblclick)="onOpenProject()"
            *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">
                暂无工区
            </td>
        </tr>
    </table>
    <mat-paginator
        class="table-paginator" 
        [pageSize]="15"
        [pageSizeOptions]="[15, 30, 50]" 
        showFirstLastButtons 
        aria-label="Select page of periodic elements">
    </mat-paginator>
</div>