<mat-accordion multi="true">
    <mat-expansion-panel class="mat-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                工区边界
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-control class = "shared-table" [selectAll]="true" [dataColumns]="surfaceColumns" [dataSource]="surfaceSource"
            (selected)="onSurfaceSelect($event)">
        </app-table-control>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                输入不可钻区
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-control class = "shared-table" [selectAll]="true" [dataColumns]="drillingColumns" [dataSource]="drillingSource"
            (selected)="onDrillingSelect($event)">
            <div class="control">
                <input type="file" multiple class="file-input" (change)="onDrillingUpload($event.target.files)"
                    #fileDrillingUpload>
                <button class="blue-text" (click)="fileDrillingUpload.click()" mat-button>上传</button>
                <div>
                    <button class="blue-text" [disabled]="selectedDrillings.length < 1" (click)="onDrillingDelete()"
                        mat-button>删除</button>
                </div>
            </div>
        </app-table-control>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                上传已有井
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-spinner *ngIf="isLoading$ | async" class="progress" diameter="30"></mat-spinner>
        <app-table-control class = "shared-table" [selectAll]="true" [dataColumns]="wellColumns" [dataSource]="wellExistingSource"
            (selected)="onWellSelect($event, true)">
            <div class="control">
                <input type="file" multiple class="file-input" (change)="onWellUpload($event.target.files)"
                    #fileWellUpload>
                <button class="blue-text" (click)="fileWellUpload.click()" mat-button>上传</button>
                <div>
                    <button class="blue-text" [disabled]="!isWellSelected" (click)="onWellDelete(true)"
                        mat-button>删除</button>
                </div>
            </div>
        </app-table-control>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-panel">
        <mat-expansion-panel-header>
            <mat-panel-title class="header-text">
                平台推荐井
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-table-control class = "shared-table" [selectAll]="true" [dataColumns]="wellColumns" [dataSource]="wellGeneratedSource"
            (selected)="onWellSelect($event, false)">
            <div class="control">
                <div></div>
                <button class="blue-text" [disabled]="!isGeneratedWellSelected" (click)="onWellDelete(false)"
                    mat-button>删除</button>
            </div>
        </app-table-control>
    </mat-expansion-panel>
</mat-accordion>