<div *ngIf="options" id="map" class="map"
    leaflet
    leafletDraw
    [leafletOptions]="options"
    [leafletLayersControl]="layersControl"
    [leafletDrawOptions]="drawOptions"
    (leafletDrawCreated)="onLeafletDrawCreated($event)"
    (leafletDrawEdited)="onLeafletDrawEdited($event)"
    (leafletDrawDeleted)="onLeafletDrawDeleted($event)"
    (leafletDrawCreated)="onDrawCreated($event)"
    [leafletMarkerCluster]="markerItems"
    [leafletMarkerClusterOptions]="markerClusterOptions"
    (leafletMarkerClusterReady)="markerClusterReady($event)"
    (leafletMapReady)="onMapReady($event)">
    <div [leafletLayers]="uneditableItems"></div>
    <div [leafletLayer]="editableItems"></div>
</div>
<app-map-manager
    class="scrollable manager"
    [hidden]="!_isEditable"
    [projectId]="projectId"
    [planId]="planId" 
    (wellSelect)="onWellSelect($event)"
    (surfaceSelect)="onSurfaceSelect($event)"
    (drillingSelect)="onDrillingSelect($event)">
</app-map-manager>
