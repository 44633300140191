<div class="wrapper">
    <app-plan-manager [projectId]="projectId" (onSelect)="onPlanSelected($event)" [planId]="planId"></app-plan-manager>
    <ng-container *ngIf="currentPlanId != null" >
        <app-graph 
            [projectId]="projectId" 
            [planId]="currentPlanId" 
            [wellSource]="wellSource"
            [generatedWellSource]="generatedWellSource"  
            [surfaceSource]="surfaceSource">
        </app-graph>
        <app-layer-manager 
            [projectId]="projectId"
            [planId]="currentPlanId" 
            (wellSelect)="wellSource = $event"
            (surfaceSelect)="surfaceSource = $event" 
            class="scrollable bt-left">
        </app-layer-manager>
        <app-computation-manager
            [projectId]="projectId"
            [planId]="currentPlanId" 
            (generatedWellSelect)="generatedWellSource = $event"
            class="scrollable bt-right">
        </app-computation-manager>
    </ng-container>
</div>