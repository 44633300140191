import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { PlanInfoModel } from 'src/app/shared/models/plans/plan-info.model';
import { PlanCreateEditModel } from 'src/app/shared/models/plans/plan-create-edit.model';
import { PlanSetAutoModel } from 'src/app/shared/models/plans/plan-setauto.model';
import { PlanBestModel } from 'src/app/shared/models/plans/plan-best.model';


@Injectable({
  providedIn: 'root'
})
export class PlanService {
  private apiUrl = environment.baseUrl + '/api/plan';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private subject$ = new Subject<string>();

  constructor(private http: HttpClient) { }

  getBestPlanSubject() {
    return this.subject$.asObservable();
  }

  setBestPlanSubject(planId: string) {
      this.subject$.next(planId);
  }

  add(data: PlanCreateEditModel): Observable<ResultModel<string>> {
    const url = `${this.apiUrl}`;
    return this.http.post<ResultModel<string>>(url, data);
  }

  edit(planId: string, data: PlanCreateEditModel): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${planId}`;
    return this.http.put<ResultEmptyModel>(url, data);
  }

  setBest(model: PlanBestModel): Observable<ResultModel<string>> {
    const url = `${this.apiUrl}/best`;
    return this.http.post<ResultModel<string>>(url, model);
  }

  auto(model: PlanSetAutoModel): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/auto`;
    return this.http.post<ResultEmptyModel>(url, model);
  }

  delete(planId: string): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${planId}`;
    return this.http.delete<ResultEmptyModel>(url);
  }

  get(planId: string): Observable<ResultModel<PlanInfoModel>> {
    const url = `${this.apiUrl}/${planId}`;
    return this.http.get<any>(url);
  }

  getLastNumber(projectId: string): Observable<ResultModel<number>> {
    const url = `${this.apiUrl}/last?projectId=${projectId}`;
    return this.http.get<any>(url);
  }

  getBest(projectId: string): Observable<ResultModel<PlanInfoModel>> {
    const url = `${this.apiUrl}/best?projectId=${projectId}`;
    return this.http.get<any>(url);
  }

  getList(projectId: string): Observable<ResultModel<PlanInfoModel[]>> {
    const url = `${this.apiUrl}/list?projectId=${projectId}`;
    return this.http.get<any>(url);
  }
}
