import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanService } from 'src/app/core/services/plan.service';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-computation-dashboard',
  templateUrl: './computation-dashboard.component.html',
  styleUrls: ['./computation-dashboard.component.scss']
})
export class ComputationDashboardComponent implements OnInit {

  selectedTabIndex = 0;
  projectId: string;
  planId: string;
  projectName: string = '';

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private planService: PlanService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];

      this.projectService.get(this.projectId).subscribe(result => {
        this.projectName = result.data.name;
      })
      this.planService.getBest(this.projectId).subscribe(result => {
        this.planId = result.data.id;
      })
      this.planService.getBestPlanSubject().subscribe(result => {
        this.planId = result;
      })
    });
  }
}
