import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PlanService } from 'src/app/core/services/plan.service';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { PlanCreateEditModel } from 'src/app/shared/models/plans/plan-create-edit.model';
import { PlanDialogDataModel } from 'src/app/shared/models/plans/plan-dialog-data.model';

@Component({
  selector: 'app-plan-edit-dialog',
  templateUrl: './plan-edit-dialog.component.html',
  styleUrls: ['./plan-edit-dialog.component.scss']
})
export class PlanEditDialogComponent implements OnInit {

    model = {} as PlanCreateEditModel;

    get isValid(): boolean {
        return !(!this.model.name || this.model.name.length === 0);
    }

    constructor(
        private readonly planService: PlanService,
        private readonly messageService: MessageService,
        public dialogRef: MatDialogRef<PlanEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PlanDialogDataModel) {

    }

    async ngOnInit() {
        this.model = (await this.planService.get(this.data.planId).toPromise()).data;
    }

    onYesClick(): void {
        this.planService.edit(this.data.planId, this.model).subscribe(
            result => {
                this.messageService.add({ severity: SlbSeverity.Success, detail: "方案更新成功", summary: '操作完成', asHtml: false, target: 'toast' });
                this.dialogRef.close({ isCompleted: true, data: this.model.name } as DialogStateModel<string>);
            },
            result => this.messageService.add({ severity: SlbSeverity.Error, detail: result.error.message, summary: 'Editing plan', asHtml: false, target: 'toast' })
        )
    }

    onNoClick(): void {
        this.dialogRef.close({ isCompleted: false } as DialogStateModel<string>);
    }
}