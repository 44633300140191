import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { ProjectService } from 'src/app/core/services/project.service';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { ProjectCreateEditModel } from 'src/app/shared/models/projects/project-create-edit.model';
import { ProjectDialogDataModel } from 'src/app/shared/models/projects/project-dialog-data.model';

@Component({
    selector: 'app-project-dialog',
    templateUrl: './project-dialog.component.html',
    styleUrls: ['./project-dialog.component.scss']
})
export class ProjectDialogComponent implements OnInit {

    model = {} as ProjectCreateEditModel;

    get isValid(): boolean {
        return !(this.isEmpty(this.model.name) || this.isEmpty(this.model.field) || this.isEmpty(this.model.userName));
    }

    constructor(
        public dialogRef: MatDialogRef<ProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjectDialogDataModel,
        private readonly projectService: ProjectService,
        private readonly messageService: MessageService
    ) {
    }

    async ngOnInit() {
        if (this.data.id){
            this.model = (await this.projectService.get(this.data.id).toPromise()).data;
        }
    }

    onYesClick(): void {
        if (this.data.id) {
            this.projectService.edit(this.data.id, this.model).subscribe(
                result => {
                    this.messageService.add({ severity: SlbSeverity.Success, detail: "工区成功更新", summary: '创建工区', asHtml: false, target: 'toast' });
                    this.dialogRef.close({ isCompleted: true, data: this.data.id } as DialogStateModel<string>);
                },
                result => this.messageService.add({ severity: SlbSeverity.Error, detail: result.error.message, summary: '编辑工区', asHtml: false, target: 'toast' })
            )
        } else {
            this.projectService.add(this.model).subscribe(
                result => {
                    this.messageService.add({ severity: SlbSeverity.Success, detail: "工区成功创建", summary: '创建工区', asHtml: false, target: 'toast' });
                    this.dialogRef.close({ isCompleted: true, data: result.data } as DialogStateModel<string>);
                },
                result => this.messageService.add({ severity: SlbSeverity.Error, detail: result.error.message, summary: '创建工区', asHtml: false, target: 'toast' })
            );
        }
    }

    onNoClick(): void {
        this.dialogRef.close({ isCompleted: false } as DialogStateModel<string>);
    }

    private isEmpty(str) {
        return (!str || str.length === 0 );
    }
}