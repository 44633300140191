import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlanService } from 'src/app/core/services/plan.service';
import { WellService } from 'src/app/core/services/well.service';
import { UnitType } from 'src/app/shared/enums/unit-type.enum';
import { PlanDialogDataModel } from 'src/app/shared/models/plans/plan-dialog-data.model';

@Component({
  selector: 'app-plan-comparison',
  templateUrl: './plan-comparison.component.html',
  styleUrls: ['./plan-comparison.component.scss']
})
export class PlanComparisonComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlanComparisonComponent>,
    private readonly wellService: WellService,
    private readonly planService: PlanService,
    @Inject(MAT_DIALOG_DATA) public data: PlanDialogDataModel
  ) { }

  UnitType = UnitType;
  ngOnInit() {

  }

  onClose(event) {
  }

}
