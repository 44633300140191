import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PlanService } from 'src/app/core/services/plan.service';
import { ConfirmDialogModel } from 'src/app/shared/models/confirm-dialog.model';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { PlanCreateEditModel } from 'src/app/shared/models/plans/plan-create-edit.model';
import { PlanInfoModel } from 'src/app/shared/models/plans/plan-info.model';
import { PlanComparisonComponent } from '../plan-comparison/plan-comparison.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { PlanEditDialogComponent } from '../plan-edit-dialog/plan-edit-dialog.component';
import { ShaleGas } from 'src/app/shared/models/shale-gas.model';
import { TotalShaleGas } from 'src/app/shared/models/total-shale-gas.model';
import { WellType } from 'src/app/shared/enums/wells/well-type.enum';
import { WellService } from 'src/app/core/services/well.service';
import { PlanRevenuModel } from 'src/app/shared/models/plans/plan-dialog-data.model';
import { ProjectService } from 'src/app/core/services/project.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlanSetAutoModel } from 'src/app/shared/models/plans/plan-setauto.model';
import { ThemePalette } from '@angular/material/core';
import { PlanBestModel } from 'src/app/shared/models/plans/plan-best.model';
import { ComputationService } from 'src/app/core/services/computation.service';

@Component({
  selector: 'app-plan-manager',
  templateUrl: './plan-manager.component.html',
  styleUrls: ['./plan-manager.component.scss']
})
export class PlanManagerComponent implements OnInit {

    @Input()
    projectId: string;

    @Input()
    planId: string;

    @Output()
    onSelect = new EventEmitter<PlanInfoModel>();

    private notifier = new Subject();

    plans: PlanInfoModel[];
    currentPlanIndex = 0;

    isAutoBestPlan: boolean;

    themePalette: ThemePalette = 'warn';

    constructor(
        private readonly planService: PlanService,
        private readonly computationService: ComputationService,
        private readonly projectService: ProjectService,
        private readonly dialog: MatDialog,
        private readonly wellService: WellService,
        private readonly messageService: MessageService) {
            this.computationService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(result => {
                this.onBestPlan();
            })
        }

    ngOnInit(): void {
        this.projectService.get(this.projectId).pipe(takeUntil(this.notifier)).subscribe(result => 
        {
            this.isAutoBestPlan = result.data.isAutoBestPlan;
        });
        this.planService.getList(this.projectId).pipe(takeUntil(this.notifier)).subscribe(result => 
        {
            this.plans = result.data;
            this.currentPlanIndex = this.plans.findIndex(plan => plan.id === this.planId);
            this.onSelect.emit(this.plans[this.currentPlanIndex]);
        });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onSelectPlan(index: number) {
        this.currentPlanIndex = index; 
        this.onSelect.emit(this.plans[index]);
    }

    async onAutoBestToggleChange(data: { checked: boolean }) {
        this.planService.auto({ projectId: this.projectId, isAuto: data.checked } as PlanSetAutoModel).pipe(takeUntil(this.notifier)).subscribe(res => {
            this.onBestPlan();
        }) 
    }

    async onCreatePlan() {
        // move the logic of creation plan name to backend
        let lastNumber = (await this.planService.getLastNumber(this.projectId).toPromise()).data;
        let model = {
            name: `方案 ${lastNumber + 1}`,
            isBest: !this.isAutoBestPlan, 
            projectId: this.projectId, 
        } as PlanCreateEditModel;
        this.planService.add(model).subscribe(res => {
            if (model.isBest) {
                this.plans.forEach(plan => plan.isBest = false);
            }
            this.plans.push(({...model, id: res.data, isBest: model.isBest}) as PlanInfoModel);
            this.messageService.add({ severity: SlbSeverity.Success, detail: `"${model.name}" 创建成功`, summary: '操作完成', asHtml: false, target: 'toast' });
        });
    }

    async onComparePlan() {
        let plans = (await this.planService.getList(this.projectId).toPromise()).data;
        let planRevenu = await this.prepareComparisionData(plans);
        const dialogRef = this.dialog.open(PlanComparisonComponent, {
            width: '540px',
            data: { 
                projectId: this.projectId ,
                plans: plans, 
                planRevenu: planRevenu
            },
        });
        dialogRef.afterClosed().subscribe((result: DialogStateModel<string>) => {
        });
    }

    onBestPlan() {
        this.planService.setBest({ isAuto: this.isAutoBestPlan, projectId: this.projectId, planId: this.plans[this.currentPlanIndex].id } as PlanBestModel).pipe(takeUntil(this.notifier)).subscribe(res => {
            let bestPlanIndex = this.plans.findIndex(a => a.id === res.data);
            
            this.plans.forEach(plan => plan.isBest = false);
            this.plans[bestPlanIndex].isBest = true;

            this.planService.setBestPlanSubject(this.plans[bestPlanIndex].id);
            if (this.isAutoBestPlan ==true){
                this.messageService.add({ severity: SlbSeverity.Success, detail: `"${this.plans[bestPlanIndex].name}" 被设为最优方案`, summary: '操作完成', asHtml: false, target: 'toast' });
            }
        })
    }

    onEditPlan() {
        const dialogRef = this.dialog.open(PlanEditDialogComponent, {
            width: '450px',
            data: { planId: this.plans[this.currentPlanIndex].id  },
        });
    
        dialogRef.afterClosed().subscribe((result: DialogStateModel<string>) => {
            if (result.isCompleted){
                this.plans[this.currentPlanIndex].name = result.data;
            }
        });
    }

    onDeletePlan() {
        if (this.plans.length > 1) {
            const dialogData = new ConfirmDialogModel("删除方案", `是否确定要删除方案'${this.plans[this.currentPlanIndex].name}'?`);
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: "400px",
              data: dialogData
            });
        
            dialogRef.afterClosed().pipe(takeUntil(this.notifier)).subscribe((isConfirmed: boolean) => {
                if (isConfirmed){
                    this.planService.delete(this.plans[this.currentPlanIndex].id).subscribe(res => {
                        this.plans.splice(this.currentPlanIndex, 1);
                        this.currentPlanIndex = this.currentPlanIndex - 1 < 0 ? this.currentPlanIndex : this.currentPlanIndex - 1;
                        this.messageService.add({ severity: SlbSeverity.Success, detail: '方案删除成功', summary: '操作完成', asHtml: false, target: 'toast' });
                    })
                }
            });
        }
    }

    async prepareComparisionData(plans: PlanInfoModel[]): Promise<PlanRevenuModel>{
        let planRevenu: PlanRevenuModel = {
            totalGas:[],
            totalReachableGas:[],
            totalRevenueP90: [],
            totalRevenueP50: [],
            totalRevenueP10: []
        };    
        for (let index = 0; index < plans.length; index++) {
          let totalshaleGasTable = this.calculateShaleGasSum((await this.wellService.getShalegasTable(plans[index].id).toPromise()).data)
          planRevenu.totalGas.push(totalshaleGasTable.totalGas)
          planRevenu.totalReachableGas.push(totalshaleGasTable.totalReachableGas);
          planRevenu.totalRevenueP90.push(totalshaleGasTable.totalRevenueP90);
          planRevenu.totalRevenueP50.push(totalshaleGasTable.totalRevenueP50);
          planRevenu.totalRevenueP10.push(totalshaleGasTable.totalRevenueP10);
        }
        for (const [key, value] of Object.entries(planRevenu)) {
            planRevenu[key] = value.map(element => ({data: element, isMax: Math.max(...value) === element}));
         }
        return planRevenu;
    }

    private calculateShaleGasSum(shaleGasTable: ShaleGas[]): TotalShaleGas {
    return {
      totalGas: shaleGasTable.reduce((n, { totalGas }) => n + totalGas, 0),
      totalReachableGas: shaleGasTable.reduce((n, { reachableGas }) => n + reachableGas, 0),
      totalRevenueP90: shaleGasTable.reduce((n, { revenueP90 }) => n + revenueP90, 0),
      totalRevenueP50: shaleGasTable.reduce((n, { revenueP50 }) => n + revenueP50, 0),
      totalRevenueP10: shaleGasTable.reduce((n, { revenueP10 }) => n + revenueP10, 0),
      verticalWellNumbers: shaleGasTable.filter(n => n.wellType !== WellType.Horizontal).length,
      horizontalWellNumbers: shaleGasTable.filter(n => n.wellType === WellType.Horizontal).length
    }
  }
}
