import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComputationService } from 'src/app/core/services/computation.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { WellService } from 'src/app/core/services/well.service';
import { WellType } from 'src/app/shared/enums/wells/well-type.enum';
import { ConfirmDialogModel } from 'src/app/shared/models/confirm-dialog.model';
import { RadiusTableModel } from 'src/app/shared/models/radius-table-model';
import { ShaleGas, ViewShaleGas } from 'src/app/shared/models/shale-gas.model';
import { WellExportModel } from 'src/app/shared/models/wells/well-export.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-computation-manager',
    templateUrl: './computation-manager.component.html',
    styleUrls: ['./computation-manager.component.scss']
})
export class ComputationManagerComponent implements OnInit {

    @Input()
    projectId: string;

    @Input()
    planId: string;

    @Output()
    generatedWellSelect = new EventEmitter<ShaleGas[]>();

    private notifier = new Subject();

    readonly MONEY_CONVERTER = 10000;
    readonly GAS_CONVERTER = 10000;

    selectedWells: ShaleGas[] = [];
    wellTypes = [
        ({ key: WellType.Deviated, value: "定向井" }) as KeyValue<WellType, string>,
        ({ key: WellType.Horizontal, value: "水平井" }) as KeyValue<WellType, string>,
        ({ key: WellType.Vertical, value: "直井" }) as KeyValue<WellType, string>,
    ];

    shaleGasTable: ShaleGas[] = [];
    generatedWellSource: ViewShaleGas[] = [];
    wellColumns = new Map<string, string>([
        ["wellName", "井名"],
        ["wellLocation", "井口位置坐标（X|Y）"],
        ["totalGas", "控制储量.万方"],
        ["reachableGas", "可采储量.万方"],
        ["revenueP90", "收益P90.万元"],
        ["revenueP50", "收益P50.万元"],
        ["revenueP10", "收益P10.万元"],
        ["wellType", "井类型"]
    ]);
    algoType = new Map<number, string>([
        [1, "经验公式"],
        [2, "理论公式"],
        [3, "机器学习模型"]
    ]);
    wellPlatformTable: WellModel[] = [];
    radiusTable: RadiusTableModel[] = [];
    planName: string;
    projectName: string; 

    get isExportAvailable(): boolean {
        return this.shaleGasTable.length > 0;
    }

    constructor(
        private planService: PlanService,
        private readonly wellService: WellService,
        private readonly surfaceService: SurfaceService,
        private readonly dialog: MatDialog,
        private readonly messageService: MessageService,
        private readonly computationService: ComputationService,
        private readonly projectSerivce: ProjectService) { }

    ngOnInit(): void {
        this.getWellData();
        this.wellService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(_ => {
            this.getWellData();
        });
        this.computationService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(_ => {
            this.getWellData();
            this.generatedWellSource = []
            this.generatedWellSelect.emit([]);
        });
        this.planService.get(this.planId).subscribe(result => {
            this.planName = result.data.name
        });
        this.projectSerivce.get(this.projectId).subscribe(result => {
            this.projectName = result.data.name
        });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onGeneratedWellSelect(data: ShaleGas[]) {
        this.generatedWellSelect.emit(data);
    }

    getWellData() {
        if (this.planId) {
            this.getShaleGasTable()
        } else {
            this.planService.getBest(this.projectId).subscribe(result => {
                this.planId = result.data.id;
                this.getShaleGasTable()
            })
        }
    }

    onWellSelect(data: ShaleGas[]) {
        this.selectedWells = data;
        this.generatedWellSelect.emit(data);
    }

    exportCSV() {
        var header = Object.keys(this.generatedWellSource[0]);
        let csv = this.generatedWellSource.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
        let csvDistinct = Array.from(new Set(csv));
    
        header = ["ID","井名", "井类型", "井口位置坐标（X|Y）", "控制储量.万方", "可采储量.万方", "收益P90.万元", "收益P50.万元","收益P10.万元"]

        csvDistinct.unshift(header.join(','));
        let csvArray = csvDistinct.join('\r\n');
        const blob = new Blob(["\uFEFF"+csvArray], { type: 'text/csv' });
        let csvName = "工区" + this.projectName +"-" + this.planName + "-单井储量表.csv";
        saveAs(blob, csvName);
    }

    exportWellHeadCSV() {
        this.wellService.getList(this.projectId, this.planId, false).subscribe(result => {
            this.wellPlatformTable = result.data.map((x) => {
                return {
                    id: x.id,
                    name: x.name,
                    headX: x.headX,
                    headY: x.headY,
                    isExisting: x.isExisting,
                    wellType: x.wellType,
                    wellTypeName:  this.getWellType(x.wellType),
                    padName: x.padName,
                    passSurface:x.passSurface
                } as WellModel
            });
            var header = ["name", "headX", "headY", "wellTypeName", "padName","passSurface"]
            let csv = this.wellPlatformTable.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
            header = ["井名", "井台坐标X", "井台坐标Y", "井类型", "井台名称","目的层"]
            csv.unshift(header.join(','));
            let csvArray = csv.join('\r\n');
            const blob = new Blob(["\uFEFF"+csvArray], { type: 'text/csv;charset=utf-8' });
            let csvName = "工区" + this.projectName +"-" + this.planName + "-井头信息表.csv";
            saveAs(blob, csvName);
        });
    }

    async exportRadiusCSV() {
    let res = await this.surfaceService.getList(this.projectId).toPromise()
    var surfaces = res.data
    for (let surface of surfaces){
        let resPoint =  await this.computationService.getGeneratedTargetPoints(surface.id, this.planId).toPromise();
        let point = resPoint.data;
        let resPara = await this.computationService.getDeflatedRadius(this.planId,surface.id).toPromise();
        let para = resPara.data
        let method = this.algoType.get(para.selectedFormulaType)
        if (method == "经验公式"){
            para.theoryFormula.parameterC = -999;
            para.theoryFormula.parameterM = -999;
            para.theoryFormula.parameterT = -999;
        }
        if (method == "理论公式"){
            para.experienceFormula.parameterA = -999;
            para.experienceFormula.parameterB = -999;
        }
        if (method == "机器学习模型"){
            para.theoryFormula.parameterC = -999;
            para.theoryFormula.parameterM = -999;
            para.theoryFormula.parameterT = -999;
            para.experienceFormula.parameterA = -999;
            para.experienceFormula.parameterB = -999;
        }
        let index = 0
        for (let p of point.data){
            let rModel = 
            {
                x: p.x,
                y: p.y,
                z:p.z,
                r:p.r,
                index:index,
                surfaceName: surface.name,
                method:method,
                A: para.experienceFormula.parameterA,
                B: para.experienceFormula.parameterB,
                C: para.theoryFormula.parameterC,
                M: para.theoryFormula.parameterM,
                T: para.theoryFormula.parameterT,
            }
            this.radiusTable.push(rModel)
        
        index = index +1;
        }   
    } 
    var header = ["surfaceName","index","x","y","z","r","method","A","B", "C", "M","T"];
    let csv = this.radiusTable.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
    header = ["层面名称","靶点编号","靶点坐标X","靶点坐标Y","靶点坐标Z","泄气半径","预测方法","方法参数A", "方法参数B", "方法参数C", "方法参数M","方法参数T"]
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    const blob = new Blob(["\uFEFF"+csvArray], { type: 'text/csv;charset=utf-8' });
    let csvName = "工区" + this.projectName +"-" + this.planName + "-泄气半径信息表.csv";
    saveAs(blob, csvName);

}

    getShaleGasTable() {
        this.wellService.getShalegasTable(this.planId).subscribe(result => {
            this.shaleGasTable = result.data
            this.generatedWellSource = this.shaleGasTable.map((x) => {
                return {
                    id: x.id,
                    wellName: x.wellName,
                    wellType: this.getWellType(x.wellType),
                    wellLocation: `${x.headX.toFixed(1)} | ${x.headY.toFixed(1)}`,
                    reachableGas: parseFloat((x.reachableGas / this.GAS_CONVERTER).toFixed(2)),
                    revenueP10: parseFloat((x.revenueP10 / this.MONEY_CONVERTER).toFixed(1)),
                    revenueP50: parseFloat((x.revenueP50 / this.MONEY_CONVERTER).toFixed(1)),
                    revenueP90: parseFloat((x.revenueP90 / this.MONEY_CONVERTER).toFixed(1)),
                    totalGas: parseFloat((x.totalGas / this.GAS_CONVERTER).toFixed(2)),
                } as ViewShaleGas
            }).sort((a, b) => b.totalGas - a.totalGas);
        });
    }

    getWellType(welltype: WellType): string {
        return this.wellTypes.find(type => type.key === welltype).value;
    }

    exportWellTrajectory() {
        let model = {
            planId: this.planId,
            names: this.selectedWells.map(x => x.wellName)
        } as WellExportModel;
        this.wellService.export(model).pipe(takeUntil(this.notifier)).subscribe(result => {
            const blob = new Blob(result.data, { type: "text/plain;charset=utf-8" });
            saveAs(blob, "well.dev");
        })
    }

    onWellDelete() {
        if (this.selectedWells.length > 0) {
            const dialogData = new ConfirmDialogModel("删除方案", `是否确认删除所选项？`);
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                maxWidth: "400px",
                data: dialogData
            });

            dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    var promises = [];
                    for (let well of this.selectedWells) {
                        promises.push(this.wellService.delete(well.id).toPromise());
                    }
                    Promise.all(promises).then(() => {
                        this.messageService.add({ severity: SlbSeverity.Success, detail: '删除完成!', summary: '操作完成', asHtml: false, target: 'toast' });
                    }).catch(err => {
                        this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
                    }).finally(() => {
                        this.wellService.addRefreshDataSubjectEvent();
                        this.computationService.addRefreshDataSubjectEvent();
                    });
                }
            });
        }
    }
}

