import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { ProjectCreateEditModel } from 'src/app/shared/models/projects/project-create-edit.model';
import { ProjectInfoModel } from 'src/app/shared/models/projects/project-info.model';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private apiUrl = environment.baseUrl + '/api/project';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  add(data: ProjectCreateEditModel): Observable<ResultModel<string>> {
    const url = `${this.apiUrl}`;
    return this.http.post<ResultModel<string>>(url, data);
  }

  edit(projectId: string, data: ProjectCreateEditModel): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.put<ResultEmptyModel>(url, data);
  }

  delete(projectId: string): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.delete<ResultEmptyModel>(url);
  }

  get(projectId: string): Observable<ResultModel<ProjectInfoModel>> {
    const url = `${this.apiUrl}/${projectId}`;
    return this.http.get<any>(url);
  }

  getList(): Observable<ResultModel<ProjectInfoModel[]>> {
    const url = `${this.apiUrl}/list`;
    return this.http.get<any>(url);
  }
}
