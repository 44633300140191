<mat-form-field class="input-field" slbFormField>
    <mat-label class="label-text">最大狗腿度 (度)</mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.engineering.maxDoglegServerity" placeholder="默认 5度" matInput
        required />
</mat-form-field>
<mat-form-field class="input-field" slbFormField>
    <mat-label class="label-text">最大测深 (米)</mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.engineering.maxDepth" placeholder="默认 3000米" matInput
        required />
</mat-form-field>
<mat-form-field class="input-field" slbFormField>
    <mat-label class="label-text">初始造斜点深度 (米)</mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.engineering.initialBuildPointDepth" placeholder="默认 500米"
        matInput required />
</mat-form-field>
<div>
    <button class="expand-button" (click)="onExpandClick()">
        展开填写详细工程参数
        <embed src="../../../../assets/icons/expand.svg" type="image/svg+xml" />
    </button>
</div>
<ng-container *ngIf="isDetailedParameter">
    <mat-form-field class="input-field" slbFormField>
        <mat-label class='grey-label-text'><strong>转向长度</strong></mat-label>
        <input #input type="number" placeholder="" matInput />
    </mat-form-field>
    <mat-form-field class="input-field" slbFormField>
        <mat-label class='grey-label-text'><strong>生产指数</strong></mat-label>
        <input #input type="number" placeholder="" matInput />
    </mat-form-field>
    <mat-form-field class="input-field" slbFormField>
        <mat-label class='grey-label-text'><strong>终点距离</strong></mat-label>
        <input #input type="number" placeholder="" matInput />
    </mat-form-field>
    <mat-form-field class="input-field" slbFormField>
        <mat-label class='grey-label-text'><strong>终点角度</strong></mat-label>
        <input #input type="number" placeholder="" matInput />
    </mat-form-field>
</ng-container>