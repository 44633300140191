import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SlbDropdownOption } from '@slb-dls/angular-material/dropdown';

@Component({
    selector: 'ml-popup',
    templateUrl: './ml-popup.component.html',
    styleUrls: ['./ml-popup.component.scss'],
})

export class MlPopupComponent {
    constructor(
        public dialogRef: MatDialogRef<MlPopupComponent>,
    ) { }
    MlFeatures: SlbDropdownOption[] = [
        ({ viewText: '孔隙度', value: 1 }) as SlbDropdownOption,
        ({ viewText: '渗透率', value: 2 }) as SlbDropdownOption,
        ({ viewText: '饱和度', value: 3 }) as SlbDropdownOption,
        ({ viewText: 'GIIP', value: 4 }) as SlbDropdownOption,
      ];
    selectedMlFeatures: SlbDropdownOption[] = [];

    algo: SlbDropdownOption[] = [
        ({ viewText: 'R-CNN', value: 1 }) as SlbDropdownOption,
        ({ viewText: 'faster R-CNN', value: 2 }) as SlbDropdownOption,
        ({ viewText: 'yolo', value: 2 }) as SlbDropdownOption,
        ({ viewText: 'SSD', value: 3 }) as SlbDropdownOption,
        ({ viewText: 'yoloV2', value: 4}) as SlbDropdownOption,
      ];
    selectedAlgo: SlbDropdownOption[] = [];

    onClose(event) {
    }
}
