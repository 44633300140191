import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';
import { ComputationDashboardComponent } from './computation-dashboard/computation-dashboard.component';
import { LayerInputComponent } from './layer-input/layer-input.component';
import { GraphComponent } from './graph/graph.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PlanEditDialogComponent } from './plan-edit-dialog/plan-edit-dialog.component';
import { PlanManagerComponent } from './plan-manager/plan-manager.component';
import { MlPopupComponent } from './computation-input/popup/ml-popup/ml-popup.component';
import { ComputationInputComponent } from './computation-input/computation-input.component';
import { WellImageComponent } from './computation-input/popup/well-image/well-image.component';
import { FormulaImageComponent } from './computation-input/popup/formula-image/formula-image.component';
import { LayerManagerComponent } from './layer-manager/layer-manager.component';
import { ComputationManagerComponent } from './computation-manager/computation-manager.component';
import { MainParameterComponent } from './computation-input/parameters/main-parameter/main-parameter.component';
import { EconomicParameterComponent } from './computation-input/parameters/economic-parameter/economic-parameter.component';
import { TechnicalParameterComponent } from './computation-input/parameters/technical-parameter/technical-parameter.component';
import { PlanComparisonComponent } from './plan-comparison/plan-comparison.component';
import { SharedModule } from '../shared/shared.module';
import { UnitConverterPipe } from 'src/app/shared/pipes/unit.converter.pipe';
import { ColorBarComponent } from './color-bar/color-bar.component';
import { ColorBarDialogComponent } from './color-bar/color-bar-dialog/color-bar-dialog.component';
import { NgxColorsModule } from 'ngx-colors';
import { DeflatedRadiusParameterComponent } from './computation-input/parameters/deflated-radius-parameter/deflated-radius-parameter.component';
import { UploadPopupComponent } from './computation-input/popup/upload-popup/upload-popup.component';

@NgModule({
  declarations: [    
    ComputationDashboardComponent,
    ComputationManagerComponent,
    FileUploadComponent,
    LayerInputComponent,
    LayerManagerComponent,
    GraphComponent,
    PlanManagerComponent,
    PlanEditDialogComponent,
    MlPopupComponent,
    ComputationInputComponent,
    WellImageComponent,
    FormulaImageComponent,
    MainParameterComponent,
    EconomicParameterComponent,
    TechnicalParameterComponent,
    DeflatedRadiusParameterComponent,
    PlanComparisonComponent,
    ColorBarComponent,
    ColorBarDialogComponent,
    UnitConverterPipe,
    UploadPopupComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    NgxColorsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ]
})
export class ComputationModule { }
