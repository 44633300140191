import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-formula-image',
  templateUrl: './formula-image.component.html',
  styleUrls: ['./formula-image.component.scss']
})
export class FormulaImageComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<FormulaImageComponent>,
) { }

  ngOnInit(): void {
  }
  
  onClose(event) {
  }
}

