import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComputationService } from 'src/app/core/services/computation.service';
import { DrillingService } from 'src/app/core/services/drilling.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { WellService } from 'src/app/core/services/well.service';
import { WellType } from 'src/app/shared/enums/wells/well-type.enum';
import { ConfirmDialogModel } from 'src/app/shared/models/confirm-dialog.model';
import { DrillingInfoModel } from 'src/app/shared/models/drillings/drilling-info.model';
import { SurfaceModel } from 'src/app/shared/models/surfaces/surface.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-map-manager',
  templateUrl: './map-manager.component.html',
  styleUrls: ['./map-manager.component.scss']
})
export class MapManagerComponent implements OnInit {

  @Input()
  projectId: string;

  @Input()
  planId: string;

  @Output()
  wellSelect = new EventEmitter<WellModel[]>();

  @Output()
  surfaceSelect = new EventEmitter<SurfaceModel[]>();

  @Output()
  drillingSelect = new EventEmitter<DrillingInfoModel[]>();

  wellColumns = new Map<string, string>([
    ["name", "名称"],
  ]);
  wellExistingSource: WellModel[] = [];
  wellGeneratedSource: WellModel[] = [];
  selectedWells: WellModel[] = [];

  surfaceColumns = new Map<string, string>([
    ["name", "名称"],
  ]);
  surfaceSource: SurfaceModel[] = [];
  selectedSurfaces: SurfaceModel[] = [];

  drillingColumns = new Map<string, string>([
    ["name", "名称"],
  ]);
  drillingSource: DrillingInfoModel[] = [];
  selectedDrillings: DrillingInfoModel[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isWellSelected(): boolean {
    return this.selectedWells.filter(well => well.isExisting).length > 0;
  }

  get isGeneratedWellSelected(): boolean {
    return this.selectedWells.filter(well => !well.isExisting).length > 0;
  }

  wellSubscription: Subscription;
  surfaceSubscription: Subscription;
  drillingSubscription: Subscription;
  computationSubscription: Subscription;

  constructor(
    private readonly planService: PlanService,
    private readonly drillingService: DrillingService,
    private readonly surfaceService: SurfaceService,
    private readonly wellService: WellService,
    private readonly computationService: ComputationService,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService) { }

  ngOnInit() {
    this.getWellData();
    this.wellSubscription = this.wellService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getWellData();
    });

    this.getSurfaceData();
    this.surfaceSubscription = this.surfaceService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getSurfaceData();
    });

    this.getGeneratedWellData();
    this.computationSubscription = this.computationService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getGeneratedWellData();
    });

    this.getDrillingData();
    this.drillingSubscription = this.drillingService.getRefreshDataSubjectEvent().subscribe(_ => {
      this.getDrillingData();
    });
  }

  ngOnDestroy() {
    this.wellSubscription.unsubscribe()
    this.surfaceSubscription.unsubscribe()
    this.computationSubscription.unsubscribe()
    this.drillingSubscription.unsubscribe()
  }

  //when set best planId, will refresh generated well data in GIS map
  ngOnChanges(changes: any) {
    this.getGeneratedWellData();
  }

  getWellData() {
    this.wellService.getList(this.projectId, this.planId, true).subscribe(res => {
      res.data.forEach(well => well.wellTypeName = this.getWellTypeName(well.wellType))
      this.wellExistingSource = res.data;
    });

    this.wellService.getList(this.projectId, this.planId, false).subscribe(res => {
      res.data.forEach(well => well.wellTypeName = this.getWellTypeName(well.wellType))
      this.wellGeneratedSource = res.data;
    });
  }

  getSurfaceData() {
    this.surfaceService.getList(this.projectId).subscribe(res => {
      res.data[0].name = '工区边界'
      // show 1 polygon boundary
      this.surfaceSource = [res.data[0]]
    });
  }
  
  getGeneratedWellData() {
    this.wellService.getList(this.projectId, this.planId, false).subscribe(res => {
      res.data.forEach(well => well.wellTypeName = this.getWellTypeName(well.wellType))
      this.wellGeneratedSource = res.data;
    });
  }

  getDrillingData() {
    this.drillingService.getList(this.projectId).subscribe(res => {
      this.drillingSource = res.data;
    })
  }

  onWellSelect(data: WellModel[], isExisting: boolean) {
    this.selectedWells = [...this.selectedWells.filter(well => well.isExisting === !isExisting), ...data];
    this.wellSelect.emit(this.selectedWells);
  }

  onWellUpload(files: File[]) {
    this.isLoading$.next(true);
    var promises = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file);
      promises.push(this.wellService.upload(formData, this.projectId).toPromise());
    }
    Promise.all(promises).then(() => {
      this.messageService.add({ severity: SlbSeverity.Success, detail: '上传完成!', summary: '操作完成', asHtml: false, target: 'toast' });
    }).catch(err => {
      this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
    }).finally(() => {
      this.isLoading$.next(false);
      this.wellService.addRefreshDataSubjectEvent();
    });
  }

  onWellDelete(isWellExisting: boolean) {
    if (this.selectedWells.length > 0) {
      const dialogData = new ConfirmDialogModel("删除方案", `是否确认删除所选项？`);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.isLoading$.next(true);
          var promises = [];
          for (let well of this.selectedWells.filter(well => well.isExisting === isWellExisting)) {
            promises.push(this.wellService.delete(well.id).toPromise());
          }
          Promise.all(promises).then(() => {
            this.messageService.add({ severity: SlbSeverity.Success, detail: '删除完成!', summary: '操作完成', asHtml: false, target: 'toast' });
          }).catch(err => {
            this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
          }).finally(() => {
            this.isLoading$.next(false);
            this.wellService.addRefreshDataSubjectEvent();
          });
        }
      });
    }
  }

  onDrillingSelect(data: DrillingInfoModel[]) {
    this.selectedDrillings = data;
    this.drillingSelect.emit(data);
  }

  onDrillingUpload(files: File[]) {
    this.isLoading$.next(true);
    var promises = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file);
      promises.push(this.drillingService.upload(formData, this.projectId).toPromise());
    }
    Promise.all(promises).then(() => {
      this.messageService.add({ severity: SlbSeverity.Success, detail: '上传完成!', summary: '操作完成', asHtml: false, target: 'toast' });
    }).catch(err => {
      this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
    }).finally(() => {
      this.isLoading$.next(false);
      this.drillingService.addRefreshDataSubjectEvent();
    });
  }

  onDrillingDelete() {
    if (this.selectedDrillings.length > 0) {
      const dialogData = new ConfirmDialogModel("删除方案", `是否确认删除所选项？`);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          var promises = [];
          for (let drilling of this.selectedDrillings) {
            promises.push(this.drillingService.delete(drilling.id).toPromise());
          }
          Promise.all(promises).then(() => {
            this.messageService.add({ severity: SlbSeverity.Success, detail: '删除完成!', summary: '操作完成', asHtml: false, target: 'toast' });
          }).catch(err => {
            this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' })
          }).finally(() => {
            this.drillingService.addRefreshDataSubjectEvent();
          });
        }
      });
    }
  }

  onSurfaceSelect(data: SurfaceModel[]) {
    this.selectedSurfaces = data;
    this.surfaceSelect.emit(data);
  }

  private getWellTypeName(type: WellType): string {
    let result = '';
    switch (type){
      case WellType.Deviated: result = '定向井'; break;
      case WellType.Horizontal: result = '水平井'; break;
      case WellType.Vertical: result = '直井'; break;
    }
    return result;
  }
}