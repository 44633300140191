import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconRegistry } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { DomSanitizer } from '@angular/platform-browser';
import { AgGridModule } from '@ag-grid-community/angular';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SlbNavigationFrameworkModule } from '@slb-dls/angular-material/navigation-framework';
import { SlbSharedModule } from '@slb-dls/angular-material/shared';
import { SlbNotificationModule, MessageService } from '@slb-dls/angular-material/notification';
import { SlbNotificationsPanelModule } from '@slb-dls/angular-material/notifications-panel';
import { SlbLogoutModule } from '@slb-dls/angular-material/logout';
import { SlbPopoverModule } from '@slb-dls/angular-material/popover';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { SlbBreadcrumbsModule } from '@slb-dls/angular-material/breadcrumbs';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { SLB_MOMENT_DATE_FORMATS } from '@slb-dls/angular-material/date-and-time-pickers';
import { SLB_THEMING_OPTIONS } from '@slb-dls/angular-material/core';
import { SlbDropdownModule } from '@slb-dls/angular-material/dropdown';
import { SlbDlsGridModule } from '@slb-dls/angular-material/ag-grid';
import { themeConfig } from 'src/themes/theme.config';
import { SlbTabControlModule } from '@slb-dls/angular-material/tab-control';
import { SlbRadioButtonGroupModule } from '@slb-dls/angular-material/radio-button-group';
import { SlbSearchModule } from '@slb-dls/angular-material/search';
import { SlbPaginationControlModule } from '@slb-dls/angular-material/pagination-control';
import { SlbCardGroupModule } from '@slb-dls/angular-material/card-group';
import { SlbModalModule}  from '@slb-dls/angular-material/modal';
import { MatPaginatorModule } from '@angular/material/paginator';


const matModules = [
  MatTableModule,
  MatPaginatorModule,
  MatSidenavModule,
  FormsModule,
  ReactiveFormsModule,

  MatMomentDateModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatCardModule,
  MatExpansionModule,
  MatChipsModule,
  AgGridModule,
  MatGridListModule,

  SlbDlsGridModule,
  SlbCardGroupModule,
  SlbSharedModule,
  SlbButtonModule,
  SlbFormFieldModule,
  SlbPopoverModule,
  SlbNotificationModule,
  SlbNotificationsPanelModule,
  SlbNavigationFrameworkModule,
  SlbBreadcrumbsModule,
  SlbLogoutModule,
  SlbDropdownModule,
  SlbDlsGridModule,
  SlbTabControlModule,
  SlbRadioButtonGroupModule,
  SlbSearchModule,
  SlbPaginationControlModule,
  SlbModalModule
]


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const defaultColor = {
  color: 'primary',
};

@NgModule({
  imports: [
    CommonModule,
    ...matModules
  ],
  exports: matModules,
  providers: [
    { provide: SLB_THEMING_OPTIONS, useValue: themeConfig },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: MAT_DATE_FORMATS, useValue: SLB_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MessageService, useClass: MessageService },
  ],
})
export class MaterialModule {

  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));
  }
}