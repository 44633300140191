import { SelectedAlgoType } from "../../enums/algo-type.enum";
import { DeepLearningModel } from "./deeplearning.model";
import { EqualDistanceModel } from "./equal-distance.model";
import { InteligentWellPlanningModel } from "./inteligent-well-planning.model";

export class ComputationModel {
    selectedAlgoType = SelectedAlgoType.InteligentWellPlanning
    inteligentWellPlanning = new InteligentWellPlanningModel();
    equalDistance = new EqualDistanceModel();
    deepLearningModel:  DeepLearningModel;
}