<slb-navigation-framework 
  [applicationLogo]="'assets/logo.jpg'"
  [applicationName]="'延长石油'"
  [menu]="menu"
  [hasSidenav]="true" 
  [notifications]="true"
  [pageTitle]="pageTitle"
>
  <slb-framework-toolbar>
    <ng-content *ngTemplateOutlet="toolbar"></ng-content>
  </slb-framework-toolbar>

  <slb-framework-sidenav>
    <ng-content *ngTemplateOutlet="sidenav"></ng-content>
  </slb-framework-sidenav>

  <slb-framework-content>
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </slb-framework-content>

  <slb-framework-page-header
    *ngIf="showHeader"
    displayBackButton="true"
    [pageTitle]="pageTitle"
    [items]="secondaryLinks"
  >
    <!-- * * * The content below is only a placeholder and can be replaced * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
  </slb-framework-page-header>
</slb-navigation-framework>

<ng-template #toolbar>
  <!-- * * * The content below is only a placeholder and can be replaced * * * -->
    <img class = "logo" src="../../assets/slblogo.png">
    <!-- <button slb-icon-button title="Help">
      <mat-icon svgIcon="help"></mat-icon>
    </button> -->
    <!-- <slb-notifications-panel [capacity]="notificationCount" [notificationItems]="notificationItems"
      [viewAllButtonVisible]="true">
    </slb-notifications-panel>
    <slb-logout [userName]="'John Doe'">
    </slb-logout> -->
  <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
</ng-template>

<ng-template #sidenav>
  <mat-nav-list class="app-sidenav">
    <!-- * * * The content below is only a placeholder and can be replaced * * * -->
    <a class="dls-navigation-item" title="Dashbord" [routerLink]="['dashbord']">
      <mat-icon svgIcon="layers"></mat-icon>
    </a>
    <!-- 
    <a class="dls-navigation-item" title="Themes" [routerLink]="['themes']">
      <mat-icon svgIcon="color-picker"></mat-icon>
    </a> -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
  </mat-nav-list>
</ng-template>

<mat-menu #menu="matMenu">
  <a mat-menu-item title="Dashbord" [routerLink]="['dashbord']">
    <mat-icon svgIcon="layers"></mat-icon>
    <span>Dashbord Page</span>
  </a>
  <button mat-menu-item disabled>
    <mat-icon svgIcon="more"></mat-icon>
    <span>More items</span>
  </button>
  <a mat-menu-item title="Themes" [routerLink]="['themes']">
    <mat-icon svgIcon="color-picker"></mat-icon>
    <span>Themes</span>
  </a>
</mat-menu>

<ng-template #content>
  <!-- * * * The content below is only a placeholder and can be replaced * * * -->
  <router-outlet></router-outlet>
  <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
</ng-template>