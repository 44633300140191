<mat-tab-group class="expansion-panel" headerPosition="above">
    <mat-tab>
        <ng-template mat-tab-label>
            <span>算法选择</span>
        </ng-template>
        <app-main-parameter [model]="computationModel"></app-main-parameter>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span>经济参数</span>
        </ng-template>
        <app-technical-parameter [model]="computationModel"></app-technical-parameter>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span>工程参数</span>
        </ng-template>
        <app-economic-parameter [model]="computationModel"></app-economic-parameter>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span>分层参数</span>
        </ng-template>
        <app-deflated-radius-parameter [model]="computationModel" [projectId]="projectId"></app-deflated-radius-parameter>
    </mat-tab>
</mat-tab-group>
<app-spinner-overlay *ngIf="isCalculating$ | async">
    计算中,大概需要5~10分钟
</app-spinner-overlay>
<button mat-raised-button position="bottom" class="computation-button" [disabled]="!isValid || (isCalculating$ | async)"
    (click)="onButtonClickSave()">
    {{(isCalculating$ | async) ? '计算中,大概需要5~10分钟' : '保存并运算'}}
</button>