<div class="wrapper">
    <div *ngIf="dataSource.length > 0" class="table">
        <mat-form-field *ngIf="showFilter" class="filter-input" appearance="standard">
            <mat-label>搜索</mat-label>
            <input matInput (keyup)="onFilter($event)" placeholder="输入搜索项" #input>
        </mat-form-field>
        <table mat-table [dataSource]="tableSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="onSelectAll($event)"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="onSelect($event, row)"
                                [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
                <template *ngIf="showTotal">
                    <td class= "summary-text"  mat-footer-cell *matFooterCellDef> 总计 </td>
                </template>
            </ng-container>

            <ng-container [matColumnDef]="column" *ngFor="let column of columns; let i = index">
                <th class= "title-text" mat-header-cell *matHeaderCellDef> {{dataColumns.get(column)}} </th>
                <td class= "content-text" mat-cell *matCellDef="let element"> {{element[column]}} </td>
                <template *ngIf="showTotal">
                    <td class= "summary-text"  mat-footer-cell *matFooterCellDef> {{getTotal(column) | number : '1.2'}} </td>
                </template>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="showColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: showColumns;"></tr>
            <template *ngIf="showTotal">
                <tr mat-footer-row *matFooterRowDef="showColumns; sticky: true"></tr>
            </template>
        </table>
    </div>
    <div class="tools">
        <ng-content></ng-content>
    </div>
</div>

<button 
    *ngIf="showPopUp"
    class="expand-btn" 
    (click)="onExpand()" 
    mat-button>
    <mat-icon svgIcon="fullscreen"></mat-icon>
</button>