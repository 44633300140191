import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-well-image',
  templateUrl: './well-image.component.html',
  styleUrls: ['./well-image.component.scss']
})
export class WellImageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WellImageComponent>,
) { }

  ngOnInit(): void {
  }
  
  onClose(event) {
  }
}
