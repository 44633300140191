<mat-accordion multi>
    <mat-expansion-panel *ngFor="let surface of model.inteligentWellPlanning.deflatedRadius">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{surface.surfaceName}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <div class="input-field"> 
                <mat-label  class="label-text">水平井参数设置 </mat-label>
                <input type="image" src="../../../../assets/icons/info.svg" class="icon" (click)="onPopWellImage()" />
            </div>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">水平井控半径(米)</mat-label>
                <input #input type="number" [(ngModel)]="surface.horizontalWellWidth"
                    placeholder="默认500米" matInput required />
            </mat-form-field>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">水平段长度(米)</mat-label>
                <input #input type="number" [(ngModel)]="surface.horizontalWellLength" placeholder="默认1000米" matInput required />
            </mat-form-field>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">河道方向(度)</mat-label>
                <input #input type="number" [(ngModel)]="surface.riverAngle" placeholder="默认180度" matInput
                    required />
            </mat-form-field>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">最大主应力方向(度)</mat-label>
                <input #input type="number" [(ngModel)]="surface.principalStress" placeholder="默认70度"
                    matInput required />
            </mat-form-field>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">水平井采收率(0~1)</mat-label>
                <input #input type="number" [(ngModel)]="surface.horizontalRecoveryEfficiency" placeholder="默认0.7" matInput
                    required />
            </mat-form-field>
            <div class="input-field"> 
                <mat-label  class="label-text"> 直井,定向井参数设置 </mat-label>
                <input type="image" src="../../../../assets/icons/info.svg" class="icon" (click)="onPopFormulaImage()" />
            </div>
            <mat-form-field class="input-field" slbFormField>
                <mat-label class="label-text">泄气半径公式类型</mat-label>
                <mat-select [(value)]="surface.selectedFormulaType" placeholder="Formula Type" required>
                    <mat-option *ngFor="let formula of formulaTypes" [value]="formula.key">{{formula.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="surface.selectedFormulaType === SelectedFormulaType.ExperienceFormula">
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">参数 A</mat-label>
                    <input #input type="number" [(ngModel)]="surface.experienceFormula.parameterA"
                        placeholder="默认1009.8" matInput required />
                </mat-form-field>
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">参数 B</mat-label>
                    <input #input type="number" [(ngModel)]="surface.experienceFormula.parameterB"
                        placeholder="默认0.2794" matInput required />
                </mat-form-field>
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">直井采收率(0~1)</mat-label>
                    <input #input type="number" [(ngModel)]="surface.verticalRecoveryEfficiency" placeholder="默认0.7" matInput
                        required />
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="surface.selectedFormulaType === SelectedFormulaType.TheoryFormula">
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">参数 C</mat-label>
                    <input #input type="number" [(ngModel)]="surface.theoryFormula.parameterC" placeholder="默认0.035"
                        matInput required />
                </mat-form-field>
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">参数 M</mat-label>
                    <input #input type="number" [(ngModel)]="surface.theoryFormula.parameterM" placeholder="默认0.02"
                        matInput required />
                </mat-form-field>
                <mat-form-field class="input-field" slbFormField>
                    <mat-label class="label-text">参数 T</mat-label>
                    <input #input type="number" [(ngModel)]="surface.theoryFormula.parameterT" placeholder="默认2"
                        matInput required />
                </mat-form-field>
            </ng-container>
        </div>
    </mat-expansion-panel>
</mat-accordion>