import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import * as L from 'leaflet';
import { Control, DrawEvents, featureGroup, FeatureGroup, latLng, MapOptions } from 'leaflet';
import * as proj4x from 'proj4';
import ResizeObserver from 'resize-observer-polyfill';
import { DrillingService } from 'src/app/core/services/drilling.service';
import { PlanService } from 'src/app/core/services/plan.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { WellService } from 'src/app/core/services/well.service';
import { DrillingCreateEditModel } from 'src/app/shared/models/drillings/drilling-create-edit.model';
import { DrillingDataModel } from 'src/app/shared/models/drillings/drilling-data.model';
import { DrillingInfoModel } from 'src/app/shared/models/drillings/drilling-info.model';
import { SurfaceModel } from 'src/app/shared/models/surfaces/surface.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';

@Component({
  selector: 'app-map-input',
  templateUrl: './map-input.component.html',
  styleUrls: ['./map-input.component.scss']
})
export class MapInputComponent {

  @Input()
  projectId: string;

  @Input()
  planId: string;

  _isEditable: boolean;
  @Input()
  set isEditable(value: boolean) {
    this._isEditable = value;
    this.drawOptions = {
      position: 'topright',
      draw: {
        marker: value ? {
          icon: L.icon({
            iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 41 ],
            iconUrl: './assets/drilling.png',
            shadowUrl: null
          }),
        } : false,
        polygon: value ? {} : false,
        polyline: false,
        circle: false,
        circlemarker: false,
        rectangle: false
      },
      edit: {
        edit: value ? {} : false,
        remove: value,
        featureGroup: this.editableItems
      }
    } as Control.DrawConstructorOptions;
  }
  get isEditable(): boolean {
    return this._isEditable;
  }

  proj4 = (proj4x as any).default;

  proj2333 = 'PROJCS["Xian 1980 / Gauss-Kruger zone 19",GEOGCS["Xian 1980",DATUM["Xian_1980",SPHEROID["IAG 1975",6378140,298.257,AUTHORITY["EPSG","7049"]],AUTHORITY["EPSG","6610"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4610"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",111],PARAMETER["scale_factor",1],PARAMETER["false_easting",19500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","2333"]]';
  proj2332 = 'PROJCS["Xian 1980 / Gauss-Kruger zone 18",GEOGCS["Xian 1980",DATUM["Xian_1980",SPHEROID["IAG 1975",6378140,298.257,AUTHORITY["EPSG","7049"]],AUTHORITY["EPSG","6610"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4610"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",105],PARAMETER["scale_factor",1],PARAMETER["false_easting",18500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","2332"]]';
  proj4326 = 'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';

  privacyInfo = "图源来自国家地理信息公共服务平台";
  // defualt is Satellite Layer
  satellitetLayer = L.layerGroup([
    L.tileLayer('http://t7.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=7c0a2016362db4ac5091f95b4e8b21e2', { subdomains: "1234", attribution: this.privacyInfo }),
    L.tileLayer('http://t7.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=7c0a2016362db4ac5091f95b4e8b21e2', { subdomains: "1234", attribution: this.privacyInfo }),
  ]);
  standartLayer = L.layerGroup([
    L.tileLayer('http://t7.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=7c0a2016362db4ac5091f95b4e8b21e2', { subdomains: "1234", attribution: this.privacyInfo }),
    L.tileLayer('http://t7.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=7c0a2016362db4ac5091f95b4e8b21e2', { subdomains: "1234", attribution: this.privacyInfo }),
  ]);

  options: MapOptions = {
    layers: [ this.satellitetLayer ],
    zoom: 11,
    center: latLng(36.65, 109.7)
  };

  layersControl = {
    baseLayers: {
      '天地图卫星影像': this.satellitetLayer,
      '天地图地图': this.standartLayer
    }
  }

  // Marker cluster stuff
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterOptions: L.MarkerClusterGroupOptions;
  markerItems: L.Marker[] = [];

  uneditableItems = [];
  editableItems: FeatureGroup = featureGroup();

  drawOptions: Control.DrawConstructorOptions;

  constructor(
    private wellService: WellService,
    private planService: PlanService,
    private drillingService: DrillingService,
    private messageService: MessageService,
    private surfaceService: SurfaceService,
  ) {
  }

  onMapReady(map: L.Map) {
    L.control.scale({ position: 'topleft' } as Control.ScaleOptions).addTo(map);

    // resizing a leaflet map on container resize
    const resizeObserver = new ResizeObserver(() => map.invalidateSize());
    resizeObserver.observe(document.getElementById("map"));
    // if (this.isEditable) {
    //   map.addControl(new L.Control.Draw({
    //     position: 'topright',
    //     draw: {
    //       marker: {
    //         icon: L.icon({
    //           iconSize: [25, 41],
    //           iconAnchor: [13, 41],
    //           iconUrl: './assets/drilling.png',
    //           shadowUrl: null
    //         }),
    //       },
    //       polyline: false,
    //       circle: false,
    //       circlemarker: false,
    //       rectangle: false
    //     },
    //     edit: {
    //       featureGroup: this.editableItems
    //     }
    //   }))
    // }
  }

  public onDrawCreated(e: any) {
    this.editableItems.addLayer((e as DrawEvents.Created).layer);
  }

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  onWellSelect(wells: WellModel[]){
    const wellData: L.Marker[] = [];
    wells.forEach(well => {
      var data = this.proj4(this.proj2333, this.proj4326, [well.headX, well.headY]);
      wellData.push(
        L.marker([data[1], data[0]], {
          icon: L.icon({
            iconSize: [ 25, 25 ],
            iconAnchor: [ 13, 41 ],
            iconUrl: well.isExisting ? './assets/well-icon.png' : './assets/well-icon.png',
            shadowUrl: null,
            tooltipAnchor: [ 0, 0 ]
          })
        }).bindTooltip(well.name, {
          permanent: true,
          opacity: 0.7,
          direction: 'bottom'
        })
      )
    });
    this.markerItems = wellData;
  }

  onSurfaceSelect(surfaces: SurfaceModel[]) {
    this.uneditableItems = [];

    surfaces.forEach(surface => {
      let polygonData = [];
      for (let i = 0; i < surface.location.length; i++){
        let data = this.proj4(this.proj2333, this.proj4326, [surface.location[i].x, surface.location[i].y]);
        polygonData.push([data[1], data[0]])
      }
      this.uneditableItems.push(L.polygon(polygonData, { color: 'red', fill: false } as L.PolylineOptions));
    })
  }

  onDrillingSelect(drillings: DrillingInfoModel[]){
    this.editableItems.clearLayers();
    drillings.forEach(drilling => {
      let polygonData = [];
      for (let i = 0; i < drilling.data.length; i++){
        let data = this.proj4(this.proj2333, this.proj4326, [drilling.data[i].x, drilling.data[i].y]);
        polygonData.push([data[1], data[0]])
      }
      L.polygon(polygonData, { id : drilling.id } as L.PolylineOptions).addTo(this.editableItems);
    })
  }

  onLeafletDrawCreated(data: DrawEvents.Created){
    switch (data.layerType){
      case 'polygon': {
        let drillingModel = {
          name: `不可钻区 ${formatDate(new Date(), 'yyyy/MM/dd hh:mm', 'en')}`,
          data: []
        } as DrillingCreateEditModel;
        
        for (let point of (data.layer as L.Polygon).getLatLngs()[0] as L.LatLng[]){
          let latLng = this.proj4(this.proj2333, [point.lng, point.lat]);
          drillingModel.data.push({x: latLng[0], y: latLng[1]} as DrillingDataModel);
        }

        this.petrolConsistentDrilling(drillingModel);
        this.drillingService.add(drillingModel, this.projectId).subscribe(res => {
          this.drillingService.addRefreshDataSubjectEvent();
          // add id to created drilling
          ((data.layer as L.Polygon).options as any).id = res.data;
          this.messageService.add({ severity: SlbSeverity.Success, detail: '不可钻井区域已上传!', summary: '操作完成', asHtml: false, target: 'toast' });
        });
      } break;
    }
  }

  onLeafletDrawEdited(data: DrawEvents.Edited){
    let drillingPromises = [];
    
    data.layers.getLayers().forEach(layer => {
      switch (this.getShapeType(layer)){
        case 'polygon': {
          let drillingId = ((layer as L.Polygon).options as any).id;
          let drillingModel = { data: [] } as DrillingCreateEditModel;

          for (let point of (layer as L.Polygon).getLatLngs()[0] as L.LatLng[]){
            let latLng = this.proj4(this.proj2333, [point.lng, point.lat]);
            drillingModel.data.push({x: latLng[0], y: latLng[1]} as DrillingDataModel);
          }

          this.petrolConsistentDrilling(drillingModel);
          drillingPromises.push(this.drillingService.edit(drillingId, drillingModel).toPromise())
        } break;
      }
    });

    Promise.all(drillingPromises)
      .then(() => this.messageService.add({ severity: SlbSeverity.Success, detail: '所选不可钻井区域已编辑!', summary: '操作完成', asHtml: false, target: 'toast' }))
      .catch(err => this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' }))
      .finally(() => {
        this.drillingService.addRefreshDataSubjectEvent();
      });
  }

  onLeafletDrawDeleted(data: DrawEvents.Deleted){
    let drillingPromises = [];
    
    data.layers.getLayers().forEach(layer => {
      switch (this.getShapeType(layer)){
        case 'polygon': {
          let drillingId = ((layer as L.Polygon).options as any).id;
          drillingPromises.push(this.drillingService.delete(drillingId).toPromise())
        } break;
      }
    });

    //TO-DO: maybe need to show each delete item but with a name of it
    Promise.all(drillingPromises)
      .then(() => this.messageService.add({ severity: SlbSeverity.Success, detail: '所选不可钻井区域已删除!', summary: '操作完成', asHtml: false, target: 'toast' }))
      .catch(err => this.messageService.add({ severity: SlbSeverity.Error, detail: err.error.message, asHtml: false, target: 'toast' }))
      .finally(() => {
        this.drillingService.addRefreshDataSubjectEvent();
      });
  }

  private petrolConsistentDrilling(model: DrillingCreateEditModel){
    if ((model.data[0].x !== model.data[model.data.length - 1].x) || (model.data[0].y !== model.data[model.data.length - 1].y)){
      model.data.push(model.data[0]);
    }
  }

  private getShapeType(layer) {
    if (layer instanceof L.Circle) {
        return 'circle';
    }
    if (layer instanceof L.Marker) {
        return 'marker';
    }
    if ((layer instanceof L.Polyline) && ! (layer instanceof L.Polygon)) {
        return 'polyline';
    }
    if ((layer instanceof L.Polygon) && ! (layer instanceof L.Rectangle)) {
        return 'polygon';
    }
    if (layer instanceof L.Rectangle) {
        return 'rectangle';
    }
  }
}
