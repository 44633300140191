<div class="header-title">方案对比
  <slb-modal-close-button [dialogRef]="dialogRef" (beforeClose)="onClose($event)" buttonType="Icon">
  </slb-modal-close-button>
</div>
<table style="width:100%; margin-top: 10pt;">
  <tr class=row-style>
    <th></th>
    <td class="title" *ngFor="let element of data.plans;">
      <div [ngClass]="{'is-red-title': element.isBest}">{{element.name}}</div>
      <img class="star" [src]=" element.isBest ? '../../../../../assets/icons/redstar.svg' : '../../../../../assets/icons/greystar.svg'" alt="Placeholder " />
    </td>
  </tr>
  <tr class=row-style>
    <th class="title">总控制储量</th>
    <td class="text" *ngFor="let element of data.planRevenu.totalGas">
      <div [ngClass]="{ 'is-red': element.isMax}">{{element.data | unitConverter:UnitType.Gas}}</div>
      <div class="unit">万立方米</div>
    </td>
  </tr>
  <tr class=row-style>
    <th class="title">总可采储量</th>
    <td class="text" *ngFor="let element of data.planRevenu.totalReachableGas">
      <div [ngClass]="{'is-red': element.isMax}">{{element.data | unitConverter:UnitType.Gas}}</div>
      <div class="unit">万立方米</div>
    </td>
  </tr>
  <tr class=row-style>
    <th class="title">总收益*P90</th>
    <td class="text" *ngFor="let element of data.planRevenu.totalRevenueP90;  last as isLastItem">
      <div [ngClass]="{'is-red': element.isMax}">{{element.data | unitConverter:UnitType.Money}}</div>
      <div class="unit">万元</div>
    </td>
  </tr>
  <tr class=row-style>
    <th class="title">总收益*P50</th>
    <td class="text" *ngFor="let element of data.planRevenu.totalRevenueP50">
      <div [ngClass]="{'is-red': element.isMax}">{{element.data | unitConverter:UnitType.Money}}</div>
      <div class="unit">万元</div>
    </td>
  </tr>
  <tr class=row-style>
    <th class="title">总收益*P10</th>
    <td class="text" *ngFor="let element of data.planRevenu.totalRevenueP10">
      <div [ngClass]="{'is-red': element.isMax}">{{element.data | unitConverter:UnitType.Money}}</div>
      <div class="unit">万元</div>
    </td>
  </tr>
</table>
<button mat-raised-button class="confirm-button" [mat-dialog-close]="">
  确定
</button>