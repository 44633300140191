import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '@slb-dls/angular-material/notification';
import "color-legend-element";
import { ExtraService } from 'src/app/core/services/extra.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { ColorItemModel } from 'src/app/shared/models/colors/color-item.model';
import { DialogStateModel } from 'src/app/shared/models/dialog-state.model';
import { ColorBarDialogComponent } from './color-bar-dialog/color-bar-dialog.component';

@Component({
    selector: 'app-color-bar',
    templateUrl: './color-bar.component.html',
    styleUrls: ['./color-bar.component.scss'],
})
export class ColorBarComponent implements OnInit {

    _colors: ColorItemModel[] = [];
    _colorBarTickFormat: string = this.SurfaceService.colorBarTickFormat;

    
    @Input()
    set colors(value: ColorItemModel[]){
        
        if (value.length > 0) {
            this.colorRange = value.map(a => a.hex);
            this.valueRange = [0, ...value.map(a => a.value)];
        } else {
            this.colorRange = [];
            this.valueRange = [];        
        }
        this._colors = value;
        this.Zone.run(()=>{
            this._colorBarTickFormat = this.SurfaceService.colorBarTickFormat;
        });
    }
    get colors(): ColorItemModel[]{
        return this._colors;
    }

    @Output()
    colorChanged = new EventEmitter<ColorItemModel[]>();

    colorRange = [];
    valueRange = [];
    
    @ViewChild("colorBar") colorBar: ElementRef;

    constructor(
        private readonly dialog: MatDialog,
        private extraService: ExtraService,
        private messageService: MessageService,
        private SurfaceService: SurfaceService,
        private Zone: NgZone,
    ) { }

    async ngOnInit() {
    }

    onSettingsClick() {
        const dialogRef = this.dialog.open(ColorBarDialogComponent, {
            width: '350px',
            disableClose: false,
            data: this.colors
        });

        dialogRef.afterClosed().subscribe((dialogResult: DialogStateModel<ColorItemModel[]>) => {
            if (dialogResult.btnType === 'close') return
            
            this.colors = [];
            if (dialogResult && dialogResult.isCompleted) {
                this.colors = dialogResult.data;
                this.colorChanged.emit(dialogResult.data);
            }
        });
    }
}
