import { Component, Inject,  OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableControlDialogModel } from 'src/app/shared/models/table-control-dialog.model';

@Component({
  selector: 'app-table-control-dialog',
  templateUrl: './table-control-dialog.component.html',
  styleUrls: ['./table-control-dialog.component.scss']
})
export class TableControlDialogComponent implements OnInit  {
    
  constructor(
    public dialogRef: MatDialogRef<TableControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TableControlDialogModel<any>,
  ) {
  }

  ngOnInit(): void {

  }
}