<mat-form-field class="input-field" slbFormField>
    <mat-label><strong> 水平井成本 (元/米)</strong></mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.economic.horizontalDrillingCost" placeholder="默认 3000元/米 "
        matInput required />
</mat-form-field>
<mat-form-field class="input-field" slbFormField>
    <mat-label><strong>直定井成本 (元/米)</strong></mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.economic.verticalDrillingCost" placeholder="默认 1000元/米" matInput
        required />
</mat-form-field>
<mat-form-field class="input-field" slbFormField>
    <mat-label><strong>商品率 (0~1)</strong></mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.economic.commodityRate" placeholder="'默认 商品率 0.97" matInput
        required />
</mat-form-field>

<div class="inner-radio-section ">
    <mat-label class="label-text">气价范围</mat-label>
    <slb-radio-button-group class="radio-section" [isVertical]="false" [values]="distributionOptions ">
    </slb-radio-button-group>
</div>
<mat-form-field class="input-field" slbFormField>
    <mat-label class="label-text">气价最小值(元/立方)</mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.economic.minGasPrice" placeholder=""
        matInput required />
</mat-form-field>
<mat-form-field class="input-field" slbFormField>
    <mat-label class="label-text">气价最大值(元/立方)</mat-label>
    <input #input type="number" [(ngModel)]="model.inteligentWellPlanning.economic.maxGasPrice" placeholder=""
        matInput required />
</mat-form-field>

<div class="inner-radio-section">
    <mat-label class="label-text">采收率范围</mat-label>
    <slb-radio-button-group class="radio-section " [isVertical]="false " [values]="distributionOptions ">
    </slb-radio-button-group>
</div>