<div class="header-title">输入地层体积系数
  <slb-modal-close-button [dialogRef]="dialogRef" (beforeClose)="onClose($event)" buttonType="Icon">
  </slb-modal-close-button>
</div>
<table mat-table [dataSource]="data.surfaceParameter" class="mat-elevation-z8">
    <ng-container matColumnDef="surfaceName">
      <th mat-header-cell *matHeaderCellDef>层面名称</th>
      <td mat-cell *matCellDef="let element" > {{element.surfaceName}} </td>
    </ng-container>
    <ng-container matColumnDef="geologicalParameter">
      <th mat-header-cell *matHeaderCellDef>地层体积系数参数</th>
      <td mat-cell *matCellDef="let element"> 
        <mat-form-field class="input-field"  slbFormField>
          <input  #input type="number" [(ngModel)]="element.giipModel.geologicalParameter" placeholder="0~1" matInput required />
        </mat-form-field>  
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-dialog-actions align="end">
  <button class = "confirm-button" mat-button [disabled]="!isValid" [mat-dialog-close] cdkFocusInitial (click)="onSurfaceUpload()">确认上传</button>
</mat-dialog-actions>