<ng-container *ngIf="colors.length > 0">
    <!--  categorical", "continuous", "discrete", "threshold". -->
    <color-legend #colorBar
        (click)="onSettingsClick()"
        class="color-bar"
        scaleType="threshold"
        width="700"
        [range]='colorRange' 
        [domain]='valueRange'
        titleText=""
        tickFormat="{{_colorBarTickFormat}}">
    </color-legend>
</ng-container>