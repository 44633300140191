import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { WellModel } from 'src/app/shared/models/wells/well.model';
import { WellInfoModel } from 'src/app/shared/models/wells/well-info.model';
import { ShaleGas } from 'src/app/shared/models/shale-gas.model';
import { WellExportModel } from 'src/app/shared/models/wells/well-export.model';

const WELL_CACHE_SIZE = 20;

@Injectable({
    providedIn: 'root'
})
export class WellService {
    private apiUrl = environment.baseUrl + '/api/well';

    httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    private wellCache$: Observable<Array<WellInfoModel>> = of();
    private subject$ = new Subject();

    constructor(private http: HttpClient) { }

    getRefreshDataSubjectEvent() {
        return this.subject$.asObservable();
    }
  
    addRefreshDataSubjectEvent() {
        this.subject$.next();
    }

    /** Get Well **/
    get( wellId: string): Observable<ResultModel<WellInfoModel>> {
        if (!this.wellCache$[wellId]) {
            this.wellCache$[wellId] = this.loadWell(wellId).pipe(
                shareReplay(WELL_CACHE_SIZE)
            )
        }
        return this.wellCache$[wellId];
    }

    upload(filedata: FormData, projectId: string): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/upload?projectId=${projectId}`;
        return this.http.post<ResultEmptyModel>(url, filedata);
    }

    /** delete Well */
    delete(wellId: string): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/${wellId}`;
        return this.http.delete<any>(url).pipe(
            tap(_ => console.log(`delete well`)),
            catchError(this.handleError<string>(`delete well`))
        );
    }

    private loadWell(wellId:string): Observable<ResultModel<WellInfoModel>> {
        const url = `${this.apiUrl}`;
        const options = wellId ? { params: new HttpParams().set('wellId', wellId)} : {};

        console.log(`about to load ${wellId}`);
        return this.http.get<ResultModel<WellInfoModel>>(url,options).pipe(
            tap(_ => console.log(`get well`)),
            catchError(this.handleError<ResultModel<WellInfoModel>>(`get well failed`))
        );
    }

    /** Get Wells Name List*/
    getList(projectId: string, planId: string =null, isExisting: boolean = null): Observable<ResultModel<WellModel[]>> {
        const url = `${this.apiUrl}/list?projectId=${projectId}&planId=${planId}`;
        let params = new HttpParams();
        if (isExisting !== null){
            params = params.set("isExisting", isExisting.toString());
        }
        return this.http.get<any>(url, { params: params }).pipe(
            tap(_ => console.log(`get well list`)),
            catchError(this.handleError<string>(`get well list failed`))
        );
    }

    export(model: WellExportModel): Observable<ResultModel<string[]>> {
        const url = `${this.apiUrl}/export`;
        return this.http.post<ResultModel<string[]>>(url, model);
    }

      /** ShaleGas table */
    getShalegasTable(planId: string): Observable<ResultModel<ShaleGas[]>> {
    const url = `${this.apiUrl}/shalegas?planId=${planId}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`get shale gas table`)),
      catchError(this.handleError<string>(`get shale gas table failed`))
    );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
