import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { DrillingInfoModel } from 'src/app/shared/models/drillings/drilling-info.model';
import { DrillingCreateEditModel } from 'src/app/shared/models/drillings/drilling-create-edit.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DrillingService {
  private apiUrl = environment.baseUrl + '/api/drilling';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  private subject$ = new Subject();

  constructor(private http: HttpClient) { }

  getRefreshDataSubjectEvent() {
      return this.subject$.asObservable();
  }

  addRefreshDataSubjectEvent() {
      this.subject$.next();
  }

  upload(filedata: FormData, projectId: string): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/upload?projectId=${projectId}`;
    return this.http.post<ResultEmptyModel>(url, filedata);
  }

  add(data: DrillingCreateEditModel, projectId: string): Observable<ResultModel<string>> {
    const url = `${this.apiUrl}?projectId=${projectId}`;
    return this.http.post<ResultModel<string>>(url, data);
  }

  edit(drillingId: string, data: DrillingCreateEditModel): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${drillingId}`;
    return this.http.put<ResultEmptyModel>(url, data);
  }

  delete(drillingId: string): Observable<ResultEmptyModel> {
    const url = `${this.apiUrl}/${drillingId}`;
    return this.http.delete<ResultEmptyModel>(url);
  }

  getList(projectId: string): Observable<ResultModel<DrillingInfoModel[]>> {
    const url = `${this.apiUrl}/list?projectId=${projectId}`;
    return this.http.get<any>(url);
  }
}
