import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageService} from '@slb-dls/angular-material/notification';
import { ProjectService } from 'src/app/core/services/project.service';
import { ProjectInfoModel } from 'src/app/shared/models/projects/project-info.model';

@Component({
  selector: 'app-project-dashbord',
  templateUrl: './project-dashbord.component.html',
  styleUrls: ['./project-dashbord.component.scss']
})
export class ProjectDashbordComponent implements OnInit {

  selectedRow: ProjectInfoModel = null;

  constructor(
      private readonly messageService: MessageService,
      private readonly projectService: ProjectService,
      private readonly router: Router,
      private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }

  onClickProject(data: ProjectInfoModel){
      this.selectedRow = null;
      setTimeout(() => this.selectedRow = data)
  }
}
