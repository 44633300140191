<div class="wrapper">
    <div class="tool-container">
        <div class="first-row">
            <slb-dropdown class="dropdown" [options]="surfaceOptions" [(ngModel)]="selectedOption" [hint]="'请勾选层面属性'"
                [placeholder]="'请勾选层面属性'"></slb-dropdown>
            <slb-dropdown class="dropdown" [options]="zScales" [multiple]="false" [(ngModel)]="selectedZScales" (selectionChange)="onZscaleChange($event)"
                [showSelectAll]="true" [selectAllText]="'全选'" [hint]="'Z Scale'" [placeholder]="'Z Scale'">
            </slb-dropdown>
            <div class="draw-btn-container">
                <button mat-raised-button class="draw-btn" (click)="onDraw()">绘制勾选的图件</button>
            </div>
            <div class="draw-points-btn-container">
                <mat-slide-toggle
                    class="toggle-margin"
                    [color]="toggleColor"
                    [(ngModel)]="isOverlook"
                    (change)="onDraw()">俯视图</mat-slide-toggle>
                <mat-slide-toggle
                    class="toggle-margin"
                    [color]="toggleColor"
                    [(ngModel)]="showPoints"
                    (change)="onDraw()">靶点</mat-slide-toggle>
                <mat-slide-toggle
                    class="toggle-margin"
                    [color]="toggleColor"
                    [(ngModel)]="showRadius"
                    (change)="onDraw()">泄气半径</mat-slide-toggle>
            </div>
        </div>
        <div class="second-row">
            <app-color-bar [colors]="colors$ | async" (colorChanged)="onColorChanged($event)"></app-color-bar>
        </div>
    </div>
    <div fxFlex id="surface" class="chart" (chartInit)="onChartInit($event)" echarts [options]="initOption"></div>
    <mat-spinner *ngIf="isLoading$ | async" class="progress" diameter="30"></mat-spinner>
</div>