<mat-dialog-content>
    <app-table-control 
        [showTotal]="data.showTotal" 
        [showFilter]="data.showFilter" 
        [dataColumns]="data.columns" 
        [dataSource]="data.source"
        [dataSelection]="data.selection"
    >
    </app-table-control>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close] cdkFocusInitial>确认</button>
</mat-dialog-actions>