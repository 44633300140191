import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { ResultEmptyModel, ResultModel } from 'src/app/shared/models/result.model';
import { SurfaceInfoModel } from 'src/app/shared/models/surfaces/surface-info.model';
import { SurfaceModel } from 'src/app/shared/models/surfaces/surface.model';

import { environment } from 'src/environments/environment';

const SURFACE_CACHE_SIZE = 10;
console.log(environment);

@Injectable({
    providedIn: 'root'
})
export class SurfaceService {
    private apiUrl = environment.baseUrl + '/api/surface';
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    private surfaceCache$: Observable<Array<SurfaceInfoModel>> = of();
    private subject$ = new Subject();
    private isLoading$ = new Subject();

    
    public selectedSurfaceIds: Array<string> = [];
    public colorBarTickFormat: string = '.0f'; // for preserve N decimal places, default 0


    constructor(private http: HttpClient) { }

    getRefreshDataSubjectEvent() {
        return this.subject$.asObservable();
    }

    addRefreshDataSubjectEvent() {
        this.subject$.next();
    }

    getUploadingDataSubjectEvent() {
        return this.isLoading$.asObservable();
    }

    addUploadingDataSubjectEvent(isLoading: boolean) {
        this.isLoading$.next(isLoading);
    }

    getList(projectId: string): Observable<ResultModel<SurfaceModel[]>> {
        const url = `${this.apiUrl}/list?projectId=${projectId}`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`get well list`)),
            catchError(this.handleError<string>(`get well list failed`))
        );
    }

    /** Get Surface */
    getSurface(surface: string, projectId: string): Observable<ResultModel<SurfaceInfoModel>> {
        if (!this.surfaceCache$[surface]) {
            this.surfaceCache$[surface] = this.loadSurface(surface, projectId).pipe(
                shareReplay(SURFACE_CACHE_SIZE)
            )
        }

        return this.surfaceCache$[surface];
    }



        /** Delete Surface */
    delete(surfaceId: string): Observable<ResultModel<string[]>> {
        const url = `${this.apiUrl}/${surfaceId}`;
        return this.http.delete<any>(url).pipe(
            tap(_ => console.log(`delete surface`)),
            catchError(this.handleError<string>(`delete surface`))
        );
    }
        
    /** Upload **/
    upload(filedata: FormData, projectId: string): Observable<ResultEmptyModel> {
        const url = `${this.apiUrl}/upload?projectId=${projectId}`;
        return this.http.post<ResultEmptyModel>(url, filedata);
    }
   
    private loadSurface(name: string, projectId): Observable<ResultModel<SurfaceInfoModel>> {
        const url = `${this.apiUrl}`;
        const options = name
            ? { params: new HttpParams().set('name', name).set('projectId', projectId) }
            : {};
        return this.http.get<ResultModel<SurfaceInfoModel>>(url, options).pipe(
            tap(_ => console.log(`get surface`)),
            catchError(this.handleError<ResultModel<SurfaceInfoModel>>(`get surface failed`))
        );
    }
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
