import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';
import { DistributionOptionsRadioButtonInfo } from '../../component-info.constants';

@Component({
  selector: 'app-technical-parameter',
  templateUrl: './technical-parameter.component.html',
  styleUrls: ['./technical-parameter.component.scss']
})
export class TechnicalParameterComponent {

    @Input()
    model: ComputationModel;

    distributionOptions: RadioButtonValue[] = DistributionOptionsRadioButtonInfo;
}
