import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from 'src/app/material/material.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { MatGridListModule } from '@angular/material/grid-list';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapInputComponent } from './map-input/map-input.component';
import { MapManagerComponent } from './map-manager/map-manager.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TableControlComponent } from './table-control/table-control.component';
import { TableControlDialogComponent } from './table-control/table-control-dialog/table-control-dialog.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';

@NgModule({
  declarations: [
      MapInputComponent,
      MapManagerComponent,
      ConfirmDialogComponent,
      SpinnerOverlayComponent,
      TableControlComponent,
      TableControlDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
    AgGridModule.withComponents([]),
    MatGridListModule
  ],
  exports: [
    MapInputComponent,
    MapManagerComponent,
    ConfirmDialogComponent,
    SpinnerOverlayComponent,
    TableControlComponent
  ]
})
export class SharedModule { }
