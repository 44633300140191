<div class="wrapper">
    <div class="table-cell">
        <div class="table-wrapper">
            <app-project-table 
                (projectSelect)="onClickProject($event)">
            </app-project-table>
        </div>
    </div>
    <div class="map-cell">
        <app-map-input 
            *ngIf="selectedRow != null" 
            [isEditable]="false" 
            [projectId]="selectedRow.id"
            [planId]="selectedRow.planId">
        </app-map-input>
    </div>
</div>