import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { SelectedFormulaType } from 'src/app/shared/enums/formula-type.enum';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';
import { DeflatedRadiusModel } from 'src/app/shared/models/computation/deflated-radius.model';
import { FormulaImageComponent } from '../../popup/formula-image/formula-image.component';
import { MlPopupComponent } from '../../popup/ml-popup/ml-popup.component';
import { WellImageComponent } from '../../popup/well-image/well-image.component';

@Component({
  selector: 'app-deflated-radius-parameter',
  templateUrl: './deflated-radius-parameter.component.html',
  styleUrls: ['./deflated-radius-parameter.component.scss']
})
export class DeflatedRadiusParameterComponent  implements OnInit  {

  @Input()
  projectId: string;

  @Input()
  model: ComputationModel;
  
  isOpened = false;

  _dialogWellImage: MatDialogRef<WellImageComponent, any> = null;
  _dialogPopup: MatDialogRef<MlPopupComponent, any> = null;
  _dialogFormulaImage: MatDialogRef<FormulaImageComponent, any> = null;

  
  formulaTypes = [
    ({ key: SelectedFormulaType.ExperienceFormula, value: "经验公式" }) as KeyValue<SelectedFormulaType, string>,
    ({ key: SelectedFormulaType.TheoryFormula, value: "理论公式" }) as KeyValue<SelectedFormulaType, string>,
    ({ key: SelectedFormulaType.MachineLearning, value: "机器学习模型" }) as KeyValue<SelectedFormulaType, string>,
  ];

  SelectedFormulaType = SelectedFormulaType;

  surfaceSubscription: Subscription;

  constructor(
    private surfaceService: SurfaceService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getSurfaceData();
    this.surfaceSubscription = this.surfaceService.getRefreshDataSubjectEvent().subscribe(_ => this.getSurfaceData());
  }

  ngOnDestroy() {
    this.surfaceSubscription.unsubscribe()
    if (this._dialogPopup) {
      this._dialogPopup.close();
      this._dialogPopup = null;
    }
  }

  getSurfaceData() {
    this.surfaceService.getList(this.projectId).subscribe(res => {
        this.model.inteligentWellPlanning.deflatedRadius = this.model.inteligentWellPlanning.deflatedRadius.filter(value => res.data.map(a => a.id).includes(value.surfaceId))
        this.model.inteligentWellPlanning.deflatedRadius.forEach(element => element.surfaceName = res.data.find(a => a.id === element.surfaceId).name)
        
        if (this.model.inteligentWellPlanning.deflatedRadius.length < res.data.length) {
          let filteredData = res.data.filter(value => !this.model.inteligentWellPlanning.deflatedRadius.map(a => a.surfaceId).includes(value.id))
          for (let i = 0; i < filteredData.length; i++) {
              let deflatedRadius = new DeflatedRadiusModel()
              deflatedRadius.surfaceId = filteredData[i].id
              deflatedRadius.surfaceName = filteredData[i].name
              this.model.inteligentWellPlanning.deflatedRadius.push(deflatedRadius)
          }
        }
    })
  }

  onPopWellImage() {
    if (this._dialogWellImage) {
      this._dialogWellImage.close();
    }
    this._dialogWellImage = this._createWellImage();
  }

  onPopFormulaImage() {
    if (this._dialogFormulaImage) {
      this._dialogFormulaImage.close();
    }
    this._dialogFormulaImage = this._createFormulaImage();
  }

  private _createWellImage(): MatDialogRef<WellImageComponent, any> {
    const dialogRef = this.dialog.open(WellImageComponent, {
      disableClose: true,
      data: {
      },
    });

    dialogRef.afterOpened().subscribe(() => this.isOpened = true);
    dialogRef.beforeClosed().subscribe(result => {
      this.isOpened = false;
      this._dialogWellImage = null;
    });

    return dialogRef;
  }

  private _createFormulaImage(): MatDialogRef<FormulaImageComponent, any> {
    const dialogRef = this.dialog.open(FormulaImageComponent, {
      disableClose: true,
      data: {
      },
    });

    dialogRef.afterOpened().subscribe(() => this.isOpened = true);
    dialogRef.beforeClosed().subscribe(result => {
      this.isOpened = false;
      this._dialogFormulaImage = null;
    });

    return dialogRef;
  }

  openDialog(): void {
    if (this._dialogPopup) {
      this._dialogPopup.close();
    }
    this._dialogPopup = this._createDialog();
  }

  private _createDialog(): MatDialogRef<MlPopupComponent, any> {
    const dialogRef = this.dialog.open(MlPopupComponent, {
      disableClose: true,
      data: {
      },
    });

    dialogRef.afterOpened().subscribe(() => this.isOpened = true);
    dialogRef.beforeClosed().subscribe(result => {
      this.isOpened = false;
      this._dialogPopup = null;
    });

    return dialogRef;
  }
}
