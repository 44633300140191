<div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    mat-dialog-title
    class="title-content"
>
    <h5>
        <strong> 输入机器学习参数 </strong>
    </h5>
    <slb-modal-close-button
        [dialogRef]="dialogRef"
        (beforeClose)="onClose($event)"
        buttonType="Icon"
    ></slb-modal-close-button>
</div>
<div  mat-dialog-content>
    <app-file-upload class="example-section ">
        <strong>上传靶点文件用于机器学习训练</strong>
    </app-file-upload>
    <mat-form-field class="example-section " slbFormField>
        <mat-label>
            学习率
        </mat-label>
        <input
            matInput
            cdkFocusInitial
        >
        <mat-hint>
            0~0.5
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="example-section " slbFormField>
        <mat-label>
            训练集比率
        </mat-label>
        <input
            matInput
            cdkFocusInitial
        >
        <mat-hint>
            0~1
        </mat-hint>
    </mat-form-field>
    <section >
    <mat-label class="example-section-dropdown">
        选择机器学习算法
    </mat-label>
    <slb-dropdown  class="example-section-dropdown " [options]="algo" [multiple]="false" [(ngModel)]="selectedAlgo" [showSelectAll]="false" [placeholder]="'选择机器学习算法'"></slb-dropdown>
    </section>
    <section>
    <mat-label class="example-section-dropdown">
        选择机器学习特征
    </mat-label>
    <slb-dropdown  class="example-section-dropdown" [options]="MlFeatures" [multiple]="true" [(ngModel)]="selectedMlFeatures" [showSelectAll]="true" [selectAllText]="'全选'"  [placeholder]="'选择机器学习特征'"></slb-dropdown>
    </section>
</div>
<div mat-dialog-actions>
    <slb-modal-close-button
        [dialogRef]="dialogRef"
        buttonType="Secondary"
        [buttonText]="'取消'"
    ></slb-modal-close-button>
    <button mat-raised-button class = "confirm-button"
        [mat-dialog-close]=""
        
    >
        确定
    </button>
</div>
