import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '@slb-dls/angular-material/notification';
import { BehaviorSubject } from 'rxjs';
import { ComputationService } from 'src/app/core/services/computation.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { PlanInfoModel } from 'src/app/shared/models/plans/plan-info.model';
import { ShaleGas } from 'src/app/shared/models/shale-gas.model';
import { SurfaceModel } from 'src/app/shared/models/surfaces/surface.model';
import { UploadType } from 'src/app/shared/models/upload-type.model';
import { WellModel } from 'src/app/shared/models/wells/well.model';

@Component({
  selector: 'app-layer-input',
  templateUrl: './layer-input.component.html',
  styleUrls: ['./layer-input.component.scss']
})
export class LayerInputComponent implements OnInit {

    @Input()
    projectId: string;
    
    _planId;
    @Input()
    set planId(value: string) {
        this._planId = value;
        this.currentPlanId = value;
    }
    get planId(): string {
        return this._planId;
    }

    currentPlanId: string = null;

    UploadType = UploadType;
    isCalculating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    
    generatedWellSource: ShaleGas[] = [];
    wellSource: WellModel[] = [];
    surfaceSource: SurfaceModel[] = [];

    constructor(
        private readonly computationService: ComputationService,
        private readonly surfaceService: SurfaceService,
        private readonly dialog: MatDialog,
        private readonly messageService: MessageService) {}

    ngOnInit(): void {
    }

    onPlanSelected(plan: PlanInfoModel) {
        this.currentPlanId = null
        setTimeout(() => this.currentPlanId = plan.id)
    }
}
