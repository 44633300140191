<div fxFlex fxLayout="column">
  <h1 mat-dialog-title>{{data.id ? '编辑工区' : '创建工区'}} </h1>
  <div fxFlex fxLayout="column" class="content" mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>名称</mat-label>
      <input matInput [(ngModel)]="model.name" >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>区块</mat-label>
      <input matInput [(ngModel)]="model.field" >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>用户名</mat-label>
      <input matInput [(ngModel)]="model.userName" >
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button class= "confirm-button" [disabled]="!isValid" (click)="onYesClick()" cdkFocusInitial>{{data.id ? '编辑' : '创建'}}</button>
    <button mat-button (click)="onNoClick()">关闭</button>
  </div>
</div>