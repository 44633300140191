import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input} from '@angular/core';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { BehaviorSubject, Subject} from 'rxjs';
import { ComputationService } from 'src/app/core/services/computation.service';
import { SelectedFormulaType } from 'src/app/shared/enums/formula-type.enum';
import { takeUntil } from 'rxjs/operators';
import { ComputationModel } from 'src/app/shared/models/computation/computation.model';
import { SelectedAlgoType } from 'src/app/shared/enums/algo-type.enum';
import { SelectedDistanceType } from 'src/app/shared/enums/wells/distance-type.enum';
import { CalculationModel } from 'src/app/shared/models/computation/calculation.model';
import { WorkerType } from 'src/app/shared/enums/workers/worker-type.enum';
import { WellService } from 'src/app/core/services/well.service';
import { SurfaceService } from 'src/app/core/services/surface.service';
import { DrillingService } from 'src/app/core/services/drilling.service';
import { WorkerStatusType } from 'src/app/shared/enums/workers/worker-status-type.enum';

@Component({
  selector: 'app-computation-input',
  templateUrl: './computation-input.component.html',
  styleUrls: ['./computation-input.component.scss']
})

export class ComputationInputComponent implements OnInit {

  @Input()
  projectId: string;

  @Input()
  planId: string;

  get isValid(): boolean {
    let errorCount = 0;

    errorCount += 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.economic.horizontalDrillingCost) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.economic.verticalDrillingCost) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.economic.commodityRate) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.economic.minGasPrice) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.economic.maxGasPrice) ? 0 : 1;

    errorCount += 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.engineering.initialBuildPointDepth) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.engineering.maxDepth) && 
      this.isNotEmpty(this.computationModel.inteligentWellPlanning.engineering.maxDoglegServerity) ? 0 : 1;

    for (let i = 0; i < this.computationModel.inteligentWellPlanning.deflatedRadius.length; i++) {
      errorCount += 
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].riverAngle) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].principalStress) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].horizontalRecoveryEfficiency) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].verticalRecoveryEfficiency) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].horizontalWellLength) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].horizontalWellWidth) &&
        this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].length) ? 0 : 1;
      if (this.computationModel.inteligentWellPlanning.deflatedRadius[i].selectedFormulaType === SelectedFormulaType.ExperienceFormula) {
        errorCount +=
          this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].experienceFormula.parameterA) &&
          this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].experienceFormula.parameterB) ? 0 : 1;
      } else {
        errorCount +=
          this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].theoryFormula.parameterC) &&
          this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].theoryFormula.parameterM) &&
          this.isNotEmpty(this.computationModel.inteligentWellPlanning.deflatedRadius[i].theoryFormula.parameterT) ? 0 : 1;
      }
    }

    switch (this.computationModel.selectedAlgoType){
      case SelectedAlgoType.EqualDistance: {
        if (this.computationModel.equalDistance.selectedDistanceType === SelectedDistanceType.FixedWellDensity){
          errorCount +=
            this.isNotEmpty(this.computationModel.equalDistance.fixedWellDensity) ? 0 : 1;
        } else {
          errorCount +=
            this.isNotEmpty(this.computationModel.equalDistance.fixedWellSpace) ? 0 : 1;
        }
      }; break;
      case SelectedAlgoType.InteligentWellPlanning: {
    
      }; break;
      case SelectedAlgoType.DeepLearning: {

      }; break;
    }
    return errorCount == 0;
  }
  
  private notifier = new Subject();

  isCalculating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  computationModel = new ComputationModel();
  readonly MILE_TO_METER = 1000000;

  constructor(
    private computationService: ComputationService,
    private wellService: WellService,
    private surfaceService: SurfaceService,
    private drillingService: DrillingService,
    private messageService: MessageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.notificationInit();

    this.computationService.getStatus(this.planId, WorkerType.TargetPointWellTrajectory).pipe(takeUntil(this.notifier)).subscribe(result => {
      if (result.data.workerStatusType == WorkerStatusType.Pending || result.data.workerStatusType == WorkerStatusType.InProgress) {
        this.isCalculating$.next(true);
        this.checkCalculationData(this.planId);
      }
    })

    this.computationService.getComputationParameter(this.planId).subscribe(result => {
      if (result.isSuccess && result.data != null) {
        this.computationModel = result.data;
      } else {
        this.computationModel = new ComputationModel();
      }
    })
  }

  ngOnDestroy() {
    this.notifier.next()
    this.notifier.complete()
  }

  notificationInit() {
    this.wellService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(() => this.messageService.add({ severity: SlbSeverity.Info, detail: "井信息已更新,请重新触发计算", summary: '计算', asHtml: false, target: 'toast' }))
    this.surfaceService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(() => this.messageService.add({ severity: SlbSeverity.Info, detail: "层面信息已更新,请重新触发计算", summary: '计算', asHtml: false, target: 'toast' }))
    this.drillingService.getRefreshDataSubjectEvent().pipe(takeUntil(this.notifier)).subscribe(() => this.messageService.add({ severity: SlbSeverity.Info, detail: "不可钻区信息已更新,请重新触发计算", summary: '计算', asHtml: false, target: 'toast' }))
  }

  async onButtonClickSave() {
    if (!(await this.beforeCalculationValidation()))
      return;

    this.isCalculating$.next(true);
    this.computationModel = this.fixWellDensityUnitConversion(this.computationModel);
    this.computationService.saveComputationParameter(this.computationModel, this.planId).subscribe(_ => {
      this.computationService.calculateData({ projectId: this.projectId, planId: this.planId } as CalculationModel).subscribe(
        result => {
          this.checkCalculationData(this.planId);
        },
        error => {
          this.isCalculating$.next(false);
          this.messageService.add({ severity: SlbSeverity.Error, detail: error.error.message, summary: '计算', asHtml: false, target: 'toast' })
        }
      )
    },
    error => {
        this.isCalculating$.next(false);
        this.messageService.add({ severity: SlbSeverity.Error, detail:  error.error.message, summary: '保存参数', asHtml: false, target: 'toast' })
      }
    )
  }

  async checkCalculationData(planId: string) {
    let result = await this.computationService.getStatus(planId, WorkerType.TargetPointWellTrajectory).toPromise();
    if (result.data.workerStatusType === WorkerStatusType.Completed || result.data.workerStatusType === WorkerStatusType.Rejected ||result.data.workerStatusType === WorkerStatusType.None) {
      this.isCalculating$.next(false);
      this.computationService.addRefreshDataSubjectEvent();
      this.messageService.add({ 
        severity: result.data.workerStatusType === WorkerStatusType.Completed ? SlbSeverity.Success : SlbSeverity.Error, 
        detail: '保存完成!', 
        summary: '操作完成', 
        asHtml: false, 
        target: 'toast' 
      })
      return;
    }
    setTimeout(() => {
      this.checkCalculationData(planId);
    }, 10000);
  }

  private async beforeCalculationValidation(): Promise<boolean> {
    let errorCount = 0;

    let surfaceList = await this.surfaceService.getList(this.projectId).toPromise();
    if (surfaceList.data.length === 0) {
      errorCount++;
      this.messageService.add({ severity: SlbSeverity.Error, detail: "计算前请输入层面信息 ", summary: '计算', asHtml: false, target: 'toast' });
    }

    return errorCount == 0;
  }

  private isNotEmpty(str): boolean {
    return !(!str || str.length === 0 );
  }

  private fixWellDensityUnitConversion(computationModel: ComputationModel):ComputationModel {
    if (computationModel.equalDistance.selectedDistanceType == SelectedDistanceType.FixedWellDensity){
      this.computationModel.equalDistance.fixedWellSpace = Math.sqrt(this.computationModel.equalDistance.fixedWellDensity * this.MILE_TO_METER)
    }
    return this.computationModel
  }
}
