import { StrategyType } from "../../enums/wells/strategy-type.enum";
import { EconomicModel } from "./cost.model";
import { DeflatedRadiusModel } from "./deflated-radius.model";
import { EngineeringModel } from "./engineering.model";


export class InteligentWellPlanningModel {
    economic = new EconomicModel();
    engineering = new EngineeringModel();
    deflatedRadius: DeflatedRadiusModel[] = [];
    strategyType = StrategyType.MaxOil;
}